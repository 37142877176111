/* eslint-disable  */
import { useState } from 'react';
import { AutoSizer } from 'react-virtualized';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { NavLink } from 'react-router-dom';
import { useGroupData } from '../../../api/GroupDataContext';
import { useViewport } from '../../../api/ViewportContext';
import { urlFriendlyString } from '../../common/functions';

import dashboardIcon from '../../../images/icon-dashboard.png';
import professionalIcon from '../../../images/icon-professional.png';
import personalIcon from '../../../images/icon-personal.png';
import calendarIcon from '../../../images/icon-calendar.png';
import dropdownIcon from '../../../images/icon-dropdown.png';

import { ProfilePicture } from '../../common/ProfilePicture';

import './MenuItems.scss';

export default function MenuItems({ closeUI }) {
  const [groupsOpen, changeGroupsOpen] = useState(sessionStorage.getItem('groupsOpen') === 'true' || false);
  const { isMobile } = useViewport();
  const { groupData, noGroups } = useGroupData();

  const toggleDropdown = (event, type) => {
    event.preventDefault();
    if (type === 'groups') {
      changeGroupsOpen(!groupsOpen);
      sessionStorage.setItem('groupsOpen', !groupsOpen);
    }
  };

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: 'var(--neutral-gray)',
      cursor: 'pointer',
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  return (
    <div className="main-menu-container">
      <AutoSizer>
        {({ height }) => (
          <Scrollbars
            renderThumbVertical={renderThumb}
            renderThumbHorizontal={renderThumb}
            autoHide
            autoHeight
            autoHeightMin={height}
          >
            <nav className="main-menu" id="main-menu">
              <NavLink to="/" exact onClick={closeUI} onKeyUp={closeUI} title="Dashboard">
                <div className="nav-link">
                  <img src={dashboardIcon} className="nav-icon" alt="dashboard icon" />
                  <span>Dashboard</span>
                </div>
              </NavLink>
              <NavLink to="/contacts" onClick={closeUI} onKeyUp={closeUI} title="Contacts">
                <div className="nav-link">
                  <img src={personalIcon} className="nav-icon" alt="contacts icon" />
                  <span>Contacts</span>
                </div>
              </NavLink>
              <NavLink to="/groups" onClick={closeUI} onKeyUp={closeUI} title="Groups">
                <div className="nav-link">
                  <img src={personalIcon} className="nav-icon" alt="contacts icon" />
                  <span>Groups</span>
                  <div
                    className="nav-dropdown-icon"
                    onClick={(e) => toggleDropdown(e, 'groups')}
                    onKeyUp={(e) => toggleDropdown(e, 'groups')}
                    role="button"
                    tabIndex="0"
                  >
                    {!noGroups
                    && (
                      <img className={groupsOpen ? 'nav-dropdown-icon-open' : ''} src={dropdownIcon} alt="" />
                    )}
                  </div>
                </div>
              </NavLink>
              {(groupsOpen || isMobile)
              && (groupData.map((group) => (
                <NavLink
                  key={group.id}
                  to={'/group/' + group.urlString}
                  exact
                  onClick={closeUI}
                  onKeyUp={closeUI}
                  title={group.name}
                >
                  <div className="nav-link-sub">
                    <div className="nav-icon-initials">
                      <ProfilePicture
                        name={group.name}
                        colour={group.colour}
                        image={group.image}
                        width="24px"
                        height="24px"
                      />
                    </div>
                    <span>{group.name}</span>
                  </div>
                </NavLink>
              )))}
              <NavLink to="/keep-in-touch/" onClick={closeUI} onKeyUp={closeUI} title="Keep in touch">
                <div className="nav-link">
                  <img src={personalIcon} className="nav-icon" alt="keep in touch icon" />
                  <span>Keep In Touch</span>
                </div>
              </NavLink>
              <NavLink to="/timeline/" onClick={closeUI} onKeyUp={closeUI} title="Timeline">
                <div className="nav-link">
                  <img src={calendarIcon} className="nav-icon" alt="calendar icon" />
                  <span>Timeline</span>
                </div>
              </NavLink>
            </nav>
          </Scrollbars>
        )}
      </AutoSizer>
    </div>
  );
}
