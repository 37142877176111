import iconPhone from '../../images/interactions/phone.png';
import iconMeetup from '../../images/interactions/catchup.png';
import iconEmail from '../../images/interactions/email.png';
import iconMessage from '../../images/interactions/message.png';

const INTERACTION_TYPES = {
  Phone: iconPhone,
  Meetup: iconMeetup,
  Email: iconEmail,
  Message: iconMessage,
};

export function getInteractionTypes() {
  return INTERACTION_TYPES;
}
