import {
  useSortedContacts, useSortedContactsUpdate, getSortAttributes, getSortDirections,
} from '../SortingContext';
import dropdownIcon from '../../../../images/icon-dropdown.png';

const sortDirections = getSortDirections();
const sortAttributes = getSortAttributes();

const BIRTHDAY_STATES = [
  {
    ATTR: sortAttributes.BIRTHDAY.val,
    DIR: sortDirections.ASCENDING,
  },
  {
    ATTR: sortAttributes.BIRTHDAY.val,
    DIR: sortDirections.DESCENDING,
  },
];

export default function BirthdayAttr({ props }) {
  const { contact, header } = props;
  const { updateSortData } = useSortedContactsUpdate();

  if (header) {
    const { sortData } = useSortedContacts();
    const index = BIRTHDAY_STATES.findIndex((state) => (
      state.ATTR === sortData.ATTR && state.DIR === sortData.DIR
    ));

    if (index > -1) {
      return (
        <div className="attr-header attr-birthday">
          <div
            role="button"
            tabIndex="0"
            onClick={() => updateSortData(BIRTHDAY_STATES[(index + 1) % BIRTHDAY_STATES.length])}
            onKeyUp={(e) => e.key === 'Enter' && updateSortData(BIRTHDAY_STATES[(index + 1) % BIRTHDAY_STATES.length])}
          >
            Birthday
            <img
              src={dropdownIcon}
              alt=""
              style={
                {
                  transform: 'rotate(' + (sortData.DIR === sortDirections.ASCENDING ? '-90' : '90') + 'deg)',
                }
              }
            />
          </div>
        </div>
      );
    }
    return (
      <div
        className="attr-header attr-birthday"
        role="button"
        tabIndex="0"
        onClick={() => updateSortData(BIRTHDAY_STATES[0])}
        onKeyUp={(e) => e.key === 'Enter' && updateSortData(BIRTHDAY_STATES[0])}
      >
        <span>Birthday</span>
      </div>
    );
  }

  const { birthday } = contact;

  return (
    <div className="attr-container attr-birthday">
      <span>
        {[birthday?.day, birthday?.month, birthday?.year].filter(Boolean).join('/')}
      </span>
    </div>
  );
}
