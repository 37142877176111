import { useRef, useState } from 'react';
// import { useClickOutside } from '../../hooks';
import './TimelineHeader.scss';

export default function TimelineHeader({ setFilter, currType, events }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const types = [...new Set(events.map((e) => e.type))].filter(Boolean);

  /*
  useClickOutside(dropdownRef, () => {
    if (dropdownOpen) setDropdownOpen(false);
  });
  */

  return (
    <div className="timeline-header">
      <div className="timeline-type-filter">
        <div
          className="timeline-dropdown-open"
          role="button"
          tabIndex="0"
          onClick={() => setDropdownOpen(!dropdownOpen)}
          onKeyUp={(e) => e.key === 'Enter' && setDropdownOpen(!dropdownOpen)}
        >
          {currType ?? 'Select event type'}
        </div>
        {dropdownOpen
        && (
          <div className="timeline-type-dropdown" ref={dropdownRef}>
            <div
              className="timeline-type-button"
              role="button"
              tabIndex="0"
              onClick={() => { setFilter(null); setDropdownOpen(!dropdownOpen); }}
              onKeyUp={(e) => { if (e.key === 'Enter') { setFilter(null); setDropdownOpen(!dropdownOpen); } }}
            >
              All
            </div>
            {types.map((type, i) => (
              <div
                key={i}
                className="timeline-type-button"
                role="button"
                tabIndex="0"
                onClick={() => { setFilter(type); setDropdownOpen(!dropdownOpen); }}
                onKeyUp={(e) => { if (e.key === 'Enter') { setFilter(type); setDropdownOpen(!dropdownOpen); } }}
              >
                {type}
              </div>
            ))}
          </div>
        )}

      </div>
      <div />
    </div>
  );
}
