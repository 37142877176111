import { ProfilePicture } from '../../common/ProfilePicture';

export default function SearchResultsGroup({ group }) {
  return (
    <>
      <ProfilePicture
        name={group.name}
        colour={group.colour}
        image={group.image}
        width="41px"
        height="41px"
      />
      {group.name}
    </>
  );
}
