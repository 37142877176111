import { PieChart } from 'react-minimal-pie-chart';

import { useContactData } from '../../../api/ContactDataContext';

import './PieChartWidget.scss';

export default function PieChartWidget() {
  const { contactData } = useContactData();

  return (
    <div className="piechart-widget-container" style={{ maxHeight: '280px' }}>
      <h3>How you&apos;re going</h3>
      <div
        className="piechart"
        style={{ maxHeight: '380px', maxWidth: '380px' }}
      >
        <PieChart
          animate
          data={[
            {
              title: 'Healthy',
              value: contactData.filter((c) => parseInt((new Date() - new Date(c.lastContacted))
                / (60 * 60 * 1000 * 24), 10) < c.frequencyGoal && c.frequencyGoal !== 0).length,
              color: '#16c728',
            },
            {
              title: 'Needs Attention',
              value: contactData.filter((c) => parseInt((new Date() - new Date(c.lastContacted))
                / (60 * 60 * 1000 * 24), 10) > c.frequencyGoal).length,
              color: '#ffa51f',
            },
            {
              title: 'No goal',
              value: contactData.filter((c) => c.frequencyGoal === 0).length,
              color: '#ccc',
            },
          ]}
        />
        <div className="pie-desc" style={{ fontWeight: '600' }}>
          <div style={{ color: '#16c728' }}>Healthy</div>
          <div style={{ color: '#ffa51f' }}>Needs&nbsp;Attention</div>
          <div>No goal</div>
        </div>
      </div>
    </div>
  );
}
