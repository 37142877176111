import { ProfilePicture } from '../../common/ProfilePicture';
import './KeepInTouchCard.scss';

function getGoalStatusColour(frequencyGoal, lastContacted) {
  if (frequencyGoal === 0) { return '#ccc'; }
  const lastContactedDays = parseInt((new Date()
    - new Date(lastContacted)) / (60 * 60 * 1000 * 24), 10);

  if (lastContactedDays > frequencyGoal) {
    return '#ffa51f';
  }
  return '#16c728';
}

export default function KeepInTouchCard({ isDragging, contact, goal }) {
  return (
    <div
      className="kit-card-container"
      style={
        {
          boxShadow: isDragging ? '8px 8px 3px rgba(0,0,0,0.1)' : '3px 3px 2px rgba(0,0,0,0.1)',
        }
      }
    >
      <div
        className="profile-pic-status"
        style={
          {
            backgroundColor: getGoalStatusColour(goal, contact.lastContacted),
          }
        }
      >
        <ProfilePicture
          fname={contact.firstName}
          lname={contact.lastName}
          colour={contact.colour}
          image={contact.image}
          width="30px"
          height="30px"
          initialCount={2}
          contact
        />
      </div>
      <span className="kit-card-name">{[contact.firstName, contact.lastName].filter(Boolean).join(' ')}</span>
    </div>
  );
}
