import './ContactCard.scss';
import { Fragment } from 'react';
import { ProfilePicture } from '../../common/ProfilePicture';
import ExpandedView from './ExpandedView';
import MobileExpandedView from './MobileExpandedView';

function ContactCard(props) {
  const {
    contactData, setViewContact, display, enums, attributes,
  } = props;

  return (
    <>
      <div
        className="contact-card-container clear-fix"
        role="button"
        tabIndex="0"
        onKeyUp={(e) => e.key === 'Enter' && setViewContact({ type: enums.VIEW_ACTIONS.SET, contact: contactData })}
        onClick={() => setViewContact({ type: enums.VIEW_ACTIONS.SET, contact: contactData })}
      >
        <div className="contact-card-item">
          {/* <input
            type="checkbox"
            className="contact-card-checkbox"
            checked={checked}
            onClick={(e) => e.stopPropagation()}
            onChange={() => checkCard()}
          /> */}
          <div className="contact-card-profile-picture">
            <ProfilePicture
              fname={contactData.firstName}
              lname={contactData.lastName}
              colour={contactData.colour}
              image={contactData.image}
              width="51px"
              height="51px"
              initialCount={2}
              contact
            />
          </div>
          {Object.entries(attributes).map((attr) => (
            <Fragment key={contactData.id + '-' + attr[0]}>
              {attr[1]({ contact: contactData })}
            </Fragment>
          ))}
        </div>
      </div>
      {(display.view === enums.VIEWS.MOBILE || display.view === enums.VIEWS.EXPANDED)
        && display.viewContactState.contact.id === contactData.id && (
        <div className="contact-card-expanded">
          {display.view === enums.VIEWS.MOBILE ? <MobileExpandedView contact={contactData} />
            : <ExpandedView contact={contactData} />}
        </div>
      )}
    </>
  );
}

export default ContactCard;
