import ViewportProvider from './ViewportContext';
import ContactDataProvider from './ContactDataContext';
import EventDataProvider from './EventDataContext';
import GroupDataProvider from './GroupDataContext';
import SearchQueryProvider from './SearchQueryContext';
import UserDataContext from './UserDataContext';
import InteractionBoxProvider from './InteractionBoxContext';
import SortedDataProvider from '../components/pages/Contacts/SortingContext';

export default function ContextContainer({ children }) {
  return (
    <UserDataContext>
      <ViewportProvider>
        <EventDataProvider>
          <ContactDataProvider>
            <GroupDataProvider>
              <SearchQueryProvider>
                <InteractionBoxProvider>
                  <SortedDataProvider>
                    {children}
                  </SortedDataProvider>
                </InteractionBoxProvider>
              </SearchQueryProvider>
            </GroupDataProvider>
          </ContactDataProvider>
        </EventDataProvider>
      </ViewportProvider>
    </UserDataContext>
  );
}
