import { useEffect, useState } from 'react';

import { useViewport } from '../../../../api/ViewportContext';

export default function PhoneNumbers({ phoneNumbers, setPhoneNumbers, editing }) {
  const [iconsVisible, setIconsVisible] = useState(-1);
  const phoneNumberCount = phoneNumbers.filter((e) => e !== '').length;
  const { isMobile } = useViewport();

  useEffect(() => {}, []);

  if (phoneNumberCount === 0 && !editing) { return (<></>); }
  return (
    <div
      className={'cp-list' + (editing ? '' : ' cp-box')}
      style={
        {
          height: phoneNumbers.filter((e) => {
            if (editing) {
              return true;
            }
            return (e !== '');
          }).length * (editing ? 48 : 38) + (editing ? 30 : 35),
        }
      }
    >
      <h3 className="cp-field-label">{'Phone number' + (phoneNumberCount <= 1 ? '' : 's')}</h3>
      {phoneNumbers.map((number, i) => (
        <div
          className="cp-list-entry"
          style={{ height: '38px' }}
          key={i}
          onMouseEnter={() => setIconsVisible(i)}
          onMouseLeave={() => setIconsVisible(-1)}
        >
          {(!editing && number === '') || (
            <div key={i}>
              {(!editing && (iconsVisible === i || isMobile)) && (
                <>
                  <input
                    type="button"
                    className="cp-phone-icon"
                    onClick={() => window.open('tel:' + number)}
                    title="Click to call"
                  />
                  <input
                    type="button"
                    className="cp-sms-icon"
                    onClick={() => window.open('sms:' + number)}
                    title="Click to compose message"
                  />
                </>
              )}
              <input
                key={i}
                readOnly={!editing}
                autoComplete="off"
                type="text"
                name="phone"
                maxLength="15"
                value={number}
                title={number}
                placeholder="Phone number"
                onChange={(e) => {
                  phoneNumbers.splice(i, 1, e.target.value);
                  setPhoneNumbers([...phoneNumbers]);
                }}
                onFocus={() => setIconsVisible(i)}
                style={{
                  width: ((i === phoneNumbers.length - 1) && editing) ? 'calc(100% - 44px)' : '100%',
                }}
              />
              {/* Adds plus button next to last phone number */}
              {i === phoneNumbers.length - 1 && editing && (
                <div
                  className="cp-add-entry"
                  role="button"
                  tabIndex="0"
                  onClick={(e) => {
                    if (phoneNumbers[i] !== '') {
                      setPhoneNumbers(phoneNumbers.concat([''])); e.stopPropagation();
                    }
                  }}
                  onKeyUp={(e) => phoneNumbers[i] !== '' && e.key === 'Enter' && setPhoneNumbers(phoneNumbers.concat(['']))}
                >
                  +
                </div>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
