import { useState } from 'react';
import { Redirect } from 'react-router';
import { getColor } from '../../common/functions';
import { useContactData } from '../../../api/ContactDataContext';
import './EventCard.scss';
import { ProfilePicture } from '../../common/ProfilePicture';

export default function EventCard({ event }) {
  const [redirect, setRedirect] = useState(null);
  const { contactData } = useContactData();
  if (event.type === '') {
    event.type = 'uncategorised';
  }

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  return (
    <div
      className="timeline-event"
      role="button"
      tabIndex="0"
      onClick={() => setRedirect('/event/' + event.urlString)}
      onKeyUp={(e) => e.key === 'Enter' && setRedirect('/event/' + event.urlString)}
    >
      <div className="timeline-event-backdrop" />
      <div className="timeline-event-background" style={{ backgroundColor: getColor(event.type) }} />
      <div className="timeline-event-nub" style={{ backgroundColor: getColor(event.type) }} />
      <div className="timeline-event-details">
        <h1 className="timeline-event-name" style={{ color: getColor(event.type) }}>{event.name}</h1>
        <span className="timeline-event-type">{(event.type).charAt(0).toUpperCase() + event.type.slice(1) }</span>
        <span className="timeline-event-notes">{event.notes}</span>
        <div className="timeline-event-time">
          <span>
            {[new Date(event.start).toLocaleTimeString(window.navigator.language, { hour: '2-digit', minute: '2-digit' }),
              new Date(event.end).toLocaleTimeString(window.navigator.language, { hour: '2-digit', minute: '2-digit' })].join(' - ')}
          </span>
        </div>
      </div>
      <div className="timeline-event-contacts">
        {event.contacts.map((id, i) => {
          const contactInfo = contactData.find((contact) => contact.id === id);
          return (
            <div
              className={'timeline-event-contact contact-' + i}
              key={i}
              role="button"
              tabIndex="0"
              title={[contactInfo.firstName, contactInfo.lastName].filter(Boolean).join(' ')}
              onClick={(e) => { setRedirect('/contact/' + contactInfo.urlString); e.stopPropagation(); }}
              onKeyUp={(e) => e.key === 'Enter' && setRedirect('/contact/' + contactInfo.urlString)}
            >
              <ProfilePicture
                fname={contactInfo.firstName}
                lname={contactInfo.lastName}
                colour={contactInfo.colour}
                image={contactInfo.image}
                width="100%"
                height="100%"
                initialCount={2}
                contact
              />
            </div>
          );
        })}

      </div>
    </div>
  );
}
