import { useRef, useState } from 'react';
import { DateTimePicker } from 'react-rainbow-components';
import { useClickOutside } from '../hooks';
import { useContactData, useContactDataUpdate } from '../../api/ContactDataContext';
import { ProfilePicture } from '../common/ProfilePicture';
import { Tags } from '../common/Tags';
import { getInteractionTypes } from '../common/interactionTypes';
import iconDropdown from '../../images/icon-dropdown.png';
import './AddInteraction.scss';

export default function AddInteraction() {
  const { contactData } = useContactData();
  const [contacts, setContacts] = useState([]);
  const [typePopup, openTypePopup] = useState(false);
  const popupRef = useRef(null);
  const [type, setType] = useState('Meetup');
  const [note, setNote] = useState('');
  const [date, setDate] = useState(new Date());
  const { updateContactById } = useContactDataUpdate();

  useClickOutside(popupRef, () => {
    if (typePopup) openTypePopup(false);
  });

  const handleSubmit = () => {
    const newCommunication = {
      date: date.toISOString(),
      type,
      note,
    };

    const newContactData = contactData.filter((c) => contacts.includes(c.id));
    newContactData.forEach((contact) => {
      const updatedContact = contact;
      updatedContact.communicationHistory = [...contact.communicationHistory, newCommunication];
      const pastCommunicationHistory = contact.communicationHistory.filter((c) => new Date(c.date)
        - new Date() <= 0).sort((c1, c2) => new Date(c1.date)
        - new Date(c2.date));

      if (pastCommunicationHistory.length > 1) {
        updatedContact.lastContacted = pastCommunicationHistory.sort((c1, c2) => new Date(c2.date)
          - new Date(c1.date))[0].date;
      } else {
        updatedContact.lastContacted = new Date(pastCommunicationHistory[0]) < new Date()
          ? pastCommunicationHistory[0] : contact.lastContacted;
      }

      if (date - new Date() < 0) {
        updatedContact.lastContacted = date;
      }

      updateContactById(contact.id, updatedContact);
    });
  };

  return (
    <div className="ai-container">
      <div className="ai-interactions-flex">
        <h2>New Interaction</h2>
        <div
          className="ai-interactions-type"
          role="button"
          tabIndex="0"
          onClick={() => openTypePopup(!typePopup)}
          onKeyUp={(e) => e.key === 'Enter' && openTypePopup(!typePopup)}
        >
          <img className="ai-interactions-type-img" src={getInteractionTypes()[type]} alt="" />
          <img className="ai-interactions-dropdown" src={iconDropdown} alt="" />
          {typePopup
          && (
            <div className="ai-interactions-select-type" ref={popupRef}>
              {Object.entries(getInteractionTypes()).map((interactionType) => (
                <div
                  key={interactionType[0]}
                  className="ai-interactions-dropdown-type"
                  role="button"
                  tabIndex="0"
                  onClick={() => { setType(interactionType[0]); openTypePopup(false); }}
                  onKeyUp={(e) => { if (e.key === 'Enter') { setType(interactionType[0]); openTypePopup(false); } }}
                >
                  <img src={interactionType[1]} alt="" />
                  <span>{interactionType[0]}</span>
                </div>
              ))}
            </div>
          )}
        </div>

        <textarea
          placeholder="Notes"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          maxLength="100"
          rows="2"
        />
        <DateTimePicker value={date} onChange={setDate} className="ai-interactions-dtp" />
        <Tags
          tags={contacts}
          values={contactData}
          onChange={(newContacts) => setContacts(newContacts)}
          element={(id) => {
            const contact = contactData.find((c) => c.id === id);
            return (
              <>
                <ProfilePicture
                  fname={contact.firstName}
                  lname={contact.lastName}
                  colour={contact.colour}
                  image={contact.image}
                  width="28px"
                  height="28px"
                  initialCount={2}
                  contact
                />
                {[contact.firstName, contact.lastName].filter(Boolean).join(' ')}
              </>
            );
          }}
          text="Add Contacts"
          searchKeys={['firstName', 'lastName']}
          editing
        />
        <div
          className="ai-submit"
          role="button"
          tabIndex="0"
          onClick={() => handleSubmit()}
          onKeyUp={(e) => e.key === 'Enter' && handleSubmit()}
        >
          Add Interaction
        </div>
      </div>
    </div>
  );
}
