import { getColor } from '../../common/functions';

export default function SearchResultsEvent({ event }) {
  return (
    <>
      <div
        className="search-result-event-nub"
        style={{ backgroundColor: getColor(event.type) }}
      />
      {event.name}
      <span className="search-result-event-date">
        {(new Date(event.start)).toLocaleDateString(window.navigator.language, {
          month: 'short', day: '2-digit',
        })}
      </span>
    </>
  );
}
