import { useState } from 'react';
import { Redirect } from 'react-router';
import { getColor } from '../../../common/functions';
import './EventCard.scss';

export default function EventCard({ event }) {
  const [redirect, setRedirect] = useState(null);

  if (redirect) {
    return (
      <Redirect push to={redirect} />
    );
  }

  return (
    <div
      className="cp-ae-event-card"
      role="button"
      tabIndex="0"
      onClick={() => setRedirect('/event/' + event.urlString)}
      onKeyUp={(e) => e.key === 'Enter' && setRedirect('/event/' + event.urlString)}
    >
      <div
        className="cp-ae-event-card-nub"
        style={{ backgroundColor: getColor(event.type) }}
        title={event.type}
      />
      <span>{event.name}</span>
      <span className="cp-ae-event-time">
        {new Date(event.start).toLocaleTimeString(window.navigator.language, { hour: '2-digit', minute: '2-digit' })}
      </span>
    </div>
  );
}
