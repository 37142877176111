import { DateTimePicker } from 'react-rainbow-components';

const color = getComputedStyle(document.documentElement).getPropertyValue('--theme-color').replace(/\s/g, '');
const theme = {
  rainbow: {
    palette: {
      brand: color || '#0a9943',
    },
  },
};

export default function RainbowDateTimePicker({ className, value, onChange }) {
  return (
    <DateTimePicker
      className={className}
      value={value}
      onChange={onChange}
      theme={theme}
    />
  );
}

export function getTheme() {
  return theme;
}
