import axios from 'axios';

export const BASE_URL = (
  window.location.hostname.split('.').includes('dev')
    ? 'https://us-central1-phonebookplus-development.cloudfunctions.net/app'
    : 'https://phonebookplus-86f6b.firebaseapp.com'
);

export function logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  window.location.replace('https://phonebookplus.com');
}

export function reauthenticate() {
  const refreshToken = localStorage.getItem('refreshToken');

  if (refreshToken) {
    return (
      axios
        .post(`${BASE_URL}/reauth`, { refreshToken })
        .then((res) => {
          localStorage.setItem('token', res.data.accessToken);
        })
        .catch(() => {
          logout();
        })
    );
  }
  logout();
  return null;
}
