import { useViewport } from '../../../api/ViewportContext';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';

export default function HeaderContainer() {
  const { width, height } = useViewport();
  const widthBreakpoint = 600;
  const heightBreakpoint = 600;

  return (width > widthBreakpoint)
          && (height > heightBreakpoint) ? (<HeaderDesktop />) : (<HeaderMobile />);
}
