import { useState } from 'react';
import { useEventData, useEventDataUpdate } from '../../../../api/EventDataContext';
import { groupBy, urlUniqueFriendlyString } from '../../../common/functions';
import EventCard from './EventCard';
import RainbowDateTimePicker from '../../../common/RainbowDateTimePicker';

import './Events.scss';

export default function Events({ contact }) {
  const currDateTime = new Date();
  const [startTime, setStartTime] = useState(new Date(currDateTime.getTime()
                                              + 24 * 1000 * 60 * 60));
  const [endTime, setEndTime] = useState(new Date(currDateTime.getTime() + 25 * 1000 * 60 * 60));
  const [title, setTitle] = useState('');
  const [add, setAdd] = useState(false);
  const [notes, setNotes] = useState('');
  const { eventData } = useEventData();
  const { addEvent } = useEventDataUpdate();
  const timezoneOffset = new Date().getTimezoneOffset();

  let contactEvents = eventData.filter((e) => e.contacts.includes(contact.id));
  contactEvents = groupBy(contactEvents, (e) => parseInt((new Date(e.start).getTime()
                          - (timezoneOffset * 60 * 1000)) / (60 * 60 * 1000 * 24), 10));

  const handleSubmit = () => {
    if (title === '') return;
    const newEvent = {
      name: title,
      urlString: urlUniqueFriendlyString(title, eventData.map((e) => e.urlString)),
      start: startTime.toISOString(),
      end: endTime.toISOString(),
      type: '',
      notes,
      contacts: [
        contact.id,
      ],
      isInteraction: false,
    };

    addEvent(newEvent);
  };

  const updateStartTime = (val) => {
    setStartTime(val);
    setEndTime(new Date(val.getTime() + 1000 * 60 * 60));
  };

  return (
    <div className="cp-ae-container">
      {add
        ? (
          <div className="cp-ae-add-event">
            <input
              className="cp-ae-title"
              value={title}
              placeholder="Event name"
              onChange={(e) => setTitle(e.target.value)}
            />
            <p>Start Time</p>
            <RainbowDateTimePicker className="cp-event-start cp-event-dtp" value={startTime} onChange={updateStartTime} />
            <p>End Time</p>
            <RainbowDateTimePicker className="cp-event-end cp-event-dtp" value={endTime} onChange={setEndTime} />
            <textarea
              className="cp-ae-notes"
              placeholder="Notes"
              value={notes}
              rows="3"
              maxLength="100"
              onChange={(e) => setNotes(e.target.value)}
            />
            <div
              className="cp-ae-new-event"
              role="button"
              tabIndex="0"
              onClick={() => handleSubmit()}
              onKeyUp={(e) => e.key === 'Enter' && handleSubmit()}
            >
              <span>Add Event</span>
            </div>
          </div>
        )
        : (
          <div
            className="cp-ae-new-event"
            role="button"
            tabIndex="0"
            onClick={() => setAdd(true)}
            onKeyUp={(e) => e.key === 'Enter' && setAdd(true)}
          >
            <span>New Event</span>
          </div>
        )}
      <div className="cp-ae-events">
        {Array.from(contactEvents).length === 0
        && (
          <p>
            No recorded events
            {contact.firstName === '' ? '' : ' with ' + contact.firstName}
          </p>
        )}
        {Array.from(contactEvents).sort((e1, e2) => e1[0] - e2[0]).map(([days, events]) => {
          const eventDate = new Date(days * 60 * 60 * 1000 * 24);
          const date = eventDate.toLocaleDateString(navigator.language, { month: 'short', day: 'numeric' });
          const year = eventDate.toLocaleDateString(navigator.language, { year: 'numeric' });
          return (
            <div key={days}>
              <div className="cp-ae-date">
                <div className="cp-ae-date-text">
                  <p>{date + (new Date().getFullYear() === parseInt(year, 10) ? '' : ` ${year}`)}</p>
                </div>
                <div className="cp-ae-date-line" />
              </div>
              <div className="cp-ae-date-events">
                {events.sort((e1, e2) => new Date(e2) - new Date(e1)).map((event, i) => (
                  <EventCard
                    key={i}
                    event={event}
                  />
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
