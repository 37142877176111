import { useState } from 'react';
import { Redirect } from 'react-router';
import iconEmail from '../../../images/interactions/email.png';
import iconPhone from '../../../images/interactions/phone.png';
import iconMessage from '../../../images/interactions/message.png';
import iconExpand from '../../../images/icon-expand.png';

import './MobileExpandedView.scss';

export default function MobileExpandedView({ contact }) {
  const [redirect, setRedirect] = useState(null);

  if (redirect) {
    return (
      <Redirect push to={redirect} />
    );
  }
  return (
    <div className="cc-mobile">
      {
        contact.phones.length > 0 && (
          <div
            className="cc-mobile-icon"
            role="button"
            tabIndex="0"
            onClick={() => window.open('tel:' + contact.phones[0])}
            onKeyUp={(e) => e.key === 'Enter' && window.open('tel:' + contact.phones[0])}
          >
            <img src={iconPhone} alt="" />
          </div>
        )
      }
      {
        contact.phones.length > 0 && (
          <div
            className="cc-mobile-icon"
            role="button"
            tabIndex="0"
            onClick={() => window.open('sms:' + contact.phones[0])}
            onKeyUp={(e) => e.key === 'Enter' && window.open('sms:' + contact.phones[0])}
          >
            <img src={iconMessage} alt="" />
          </div>
        )
      }
      {
        contact.emails.length > 0 && (
          <div
            className="cc-mobile-icon"
            role="button"
            tabIndex="0"
            onClick={() => window.open('mailto:' + contact.emails[0])}
            onKeyUp={(e) => e.key === 'Enter' && window.open('mailto:' + contact.emails[0])}
          >
            <img src={iconEmail} alt="" />
          </div>
        )
      }
      <div
        className="cc-mobile-icon"
        role="button"
        tabIndex="0"
        onClick={() => setRedirect('/contact/' + contact.urlString)}
        onKeyUp={(e) => e.key === 'Enter' && setRedirect('/contact/' + contact.urlString)}
      >
        <img src={iconExpand} alt="" />
      </div>
    </div>
  );
}
