import socialYoutube from '../../../../images/socials/youtube.png';
import socialInstagram from '../../../../images/socials/instagram.png';
import socialFacebook from '../../../../images/socials/facebook.png';
import socialLinkedin from '../../../../images/socials/linkedin.png';
import socialTiktok from '../../../../images/socials/tiktok.png';
import socialTwitter from '../../../../images/socials/twitter.png';
import socialOther from '../../../../images/socials/other.png';

import './SocialMedias.scss';

const SOCIAL_ICONS = {
  youtube: socialYoutube,
  instagram: socialInstagram,
  facebook: socialFacebook,
  linkedin: socialLinkedin,
  tiktok: socialTiktok,
  twitter: socialTwitter,
  other: socialOther,
};

function getTypeFromLink(link) {
  let linkType = 'other';

  Object.keys(SOCIAL_ICONS).forEach((iconType) => {
    if (link.includes(iconType)) {
      linkType = iconType;
    }
  });

  return linkType;
}

export default function SocialMedias({ socialMedias, setSocialMedias, editing }) {
  const socialCount = socialMedias.filter((s) => s !== '').length;

  if (editing) {
    return (
      <div
        className="cp-list"
        style={
          {
            height: socialMedias.filter((e) => {
              if (editing) {
                return true;
              }
              return (e !== '');
            }).length * (editing ? 48 : 38) + (editing ? 40 : 35),
          }
        }
      >
        <h3 className="cp-field-label">{'Link' + (socialCount <= 1 ? '' : 's')}</h3>
        {socialMedias.map((social, i) => (
          <div
            className="cp-list-entry"
            style={{ height: '38px' }}
            key={i}
          >
            <div key={i}>
              <input
                key={i}
                autoComplete="off"
                type="text"
                name="social"
                value={social}
                placeholder="Link"
                onChange={(e) => {
                  socialMedias.splice(i, 1, e.target.value);
                  setSocialMedias([...socialMedias]);
                }}
                style={{
                  width: ((i === socialMedias.length - 1) && editing) ? 'calc(100% - 44px)' : '100%',
                }}
              />
              {(i === socialMedias.length - 1) && (
                <div
                  className="cp-add-entry"
                  role="button"
                  tabIndex="0"
                  onClick={(e) => { if (social[i] !== '') { setSocialMedias(socialMedias.concat([''])); e.stopPropagation(); } }}
                  onKeyUp={(e) => (social[i] !== '') && e.key === 'Enter' && setSocialMedias(socialMedias.concat(['']))}
                >
                  +
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }

  const formattedLinks = socialMedias.filter((s) => s !== '').map((socialMedia) => {
    const splitLink = socialMedia.split('.');
    if (splitLink[0].includes('http')) { return splitLink.join('.'); }
    if (!splitLink[0].includes('www')) {
      return ['https://www', ...splitLink].join('.');
    }
    return ['https://www', ...splitLink.slice(1)].join('.');
  });

  const icons = formattedLinks.map((socialMedia) => (
    {
      img: getTypeFromLink(socialMedia),
      link: socialMedia,
    }
  ));

  if (socialCount === 0) {
    return <></>;
  }

  return (
    <div className="sm-container">
      {icons.map((icon, i) => (
        <div
          className="sm-icon"
          key={i}
          role="button"
          tabIndex="0"
          onKeyDown={(e) => e.key === 'Enter' && window.open(icon.link, '_blank').focus()}
          onClick={() => window.open(icon.link, '_blank').focus()}
          title={icon.link}
        >
          <img src={SOCIAL_ICONS[icon.img]} alt="" />
        </div>
      ))}
    </div>
  );
}
