import { useState, useRef } from 'react';
import Fuse from 'fuse.js';
import './Tags.scss';
import { Redirect } from 'react-router';
import Scrollbars from 'react-custom-scrollbars-2';
import iconRemove from '../../../images/icon-remove.png';

export default function Tags({
  tags, values, onChange, element, text, searchKeys, editing, linkStub,
}) {
  const [redirect, setRedirect] = useState(null);
  const [search, setSearch] = useState('');
  const inputRef = useRef();
  const fuse = new Fuse(values, { keys: searchKeys, threshold: 0.2 });
  const [showResults, setShowResults] = useState(false);
  const [hover, setHover] = useState(-1);

  const results = fuse.search(search);

  const handleTagClick = (val) => {
    const { urlString } = values.find((v) => v.id === val);
    if (editing || !urlString || !linkStub) { return; }
    setRedirect(linkStub + urlString);
  };

  const getResultsBoxHeight = () => {
    if (results.length > 5) { return (5 * 40 + 'px'); }
    if (results.length > 0) { return (results.length * 40 + 'px'); }
    return 'auto';
  };

  return (
    <div className="tags-container">
      {redirect && <Redirect push to={redirect} />}
      {editing
      && (
        <div className="tags-search">
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={text}
            ref={inputRef}
            onFocus={() => setShowResults(true)}
          />
          {(showResults && search.length > 0)
          && (
            <div
              className="tags-search-results"
              style={
                {
                  top: inputRef.current ? (inputRef.current.offsetHeight) + 'px' : '30px',
                  height: getResultsBoxHeight(),
                }
              }
            >
              {results.length === 0 && <p>No results</p>}
              <Scrollbars>
                {results.map((val, i) => (
                  <div
                    key={i}
                    className="tags-search-result"
                    role="button"
                    tabIndex="0"
                    onClick={() => { onChange([...new Set(tags.concat(val.item.id))]); setSearch(''); }}
                    onKeyUp={(e) => {
                      if (!e.key === 'Enter') { return; }
                      onChange([...new Set(tags.concat(val.item.id))]);
                      setSearch('');
                    }}
                  >
                    {element(val.item.id)}
                  </div>
                ))}
              </Scrollbars>
            </div>
          )}
        </div>
      )}
      <div className="tags-selected">
        {tags.map((val, i) => (
          <div
            className="tag-selected"
            role="button"
            tabIndex="0"
            onClick={() => handleTagClick(val)}
            onKeyDown={(e) => e.key === 'Enter' && handleTagClick(val)}
            onMouseEnter={() => linkStub && setHover(i)}
            onMouseLeave={() => linkStub && setHover(-1)}
            style={
              {
                cursor: hover === i ? 'pointer' : 'auto',
                backgroundColor: hover === i ? 'var(--light-gray)' : 'var(--neutral-gray)',
              }
            }
            key={i}
          >
            {element(val)}
            {editing
            && (
              <div
                className="tag-delete"
                role="button"
                tabIndex="0"
                onClick={() => onChange(tags.filter((tag) => tag !== val))}
                onKeyUp={(e) => e.key === 'Enter' && onChange(tags.filter((tag) => tag !== val))}
              >
                <img src={iconRemove} alt="" />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
