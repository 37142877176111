import { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { useSearchQueryUpdate } from '../../../api/SearchQueryContext';
import { urlUniqueFriendlyString } from '../functions';
import './SearchBox.scss';

// MAIN //
export default function SearchBox(props) {
  const {
    width: sbWidth,
    height: sbHeight,
    fontSize: sbFontSize,
  } = props;
  const [query, setQuery] = useState('');
  const [redirect, setRedirect] = useState(null);
  const { updateSearchQuery } = useSearchQueryUpdate();

  useEffect(() => {
    updateSearchQuery(query);
    if (query !== '') {
      setRedirect('/search/' + urlUniqueFriendlyString(query, []));
    } else if (redirect !== null) {
      setRedirect('/');
    }
  }, [query]);

  return (
    <>
      <textarea
        className="search-box-main"
        placeholder="Search"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        style={{ width: sbWidth, height: sbHeight, fontSize: sbFontSize }}
      />
      {redirect
      && (
        <Redirect to={redirect} />
      )}
    </>
  );
}
