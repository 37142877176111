import { ProfilePicture } from '../../common/ProfilePicture';

export default function SearchResultsContact({ contact }) {
  return (
    <>
      <ProfilePicture
        fname={contact.firstName}
        lname={contact.lastName}
        colour={contact.colour}
        image={contact.image}
        width="41px"
        height="41px"
        initialCount={2}
        contact
      />
      {[contact.firstName, contact.lastName].filter(Boolean).join(' ')}
    </>
  );
}
