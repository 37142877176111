import { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { useGroupData } from '../../../api/GroupDataContext';
import { Tags } from '../../common/Tags';
import { ProfilePicture } from '../../common/ProfilePicture';
import './ExpandedView.scss';
import iconExpand from '../../../images/icon-expand.png';
import iconPencil from '../../../images/icon-pencil.png';

import {
  SocialMedias,
  BoxToggle,
  Events,
  Interactions,
  Notes,
  PhoneNumbers,
  Emails,
  Birthday,
} from '../Contact/fields';

export default function ExpandedView({ contact }) {
  const [frequencyGoal, setFrequencyGoal] = useState(contact.frequencyGoal);
  const [notes, setNotes] = useState(contact.notes);
  const [communicationHistory, setCommunicationHistory] = useState(contact.communicationHistory);
  const [redirect, setRedirect] = useState(null);
  const { groupData } = useGroupData();

  const [boxContent, setBoxContent] = useState({
    Timeline:
    (
      <Events
        key={contact.id}
        contact={contact}
      />
    ),
    Interactions:
    (
      <Interactions
        key={contact.id}
        frequencyGoal={frequencyGoal}
        setFrequencyGoal={setFrequencyGoal}
        communicationHistory={communicationHistory}
        setCommunicationHistory={setCommunicationHistory}
        page=""
        contact={contact}
      />
    ),
    Notes:
    (
      <Notes
        key={contact.id}
        notes={notes}
        setNotes={setNotes}
        contact={contact}
        editing
      />
    ),
  });

  useEffect(() => {
    setBoxContent({
      Timeline:
      (
        <Events
          key={contact.id}
          contact={contact}
        />
      ),
      Interactions:
      (
        <Interactions
          key={contact.id}
          frequencyGoal={frequencyGoal}
          setFrequencyGoal={setFrequencyGoal}
          communicationHistory={communicationHistory}
          setCommunicationHistory={setCommunicationHistory}
          contact={contact}
        />
      ),
      Notes:
        (
          <Notes
            key={contact.id}
            notes={notes}
            setNotes={setNotes}
            contact={contact}
            editing
          />
        ),
    });
  }, [notes, frequencyGoal, communicationHistory]);

  if (redirect) {
    return (
      <Redirect push to={'/contact/' + redirect} />
    );
  }

  return (
    <div className="cc-expanded cp-container">
      <div
        className="contacts-button cc-expand"
        role="button"
        tabIndex="0"
        onClick={() => setRedirect(contact.urlString)}
        onKeyUp={(e) => e.key === 'Enter' && setRedirect(contact.urlString)}
      >
        <img src={iconExpand} alt="" />
      </div>
      <div
        className="contacts-button cc-edit"
        role="button"
        tabIndex="0"
        onClick={() => setRedirect(contact.urlString + '/edit/')}
        onKeyUp={(e) => e.key === 'Enter' && setRedirect(contact.urlString + '/edit/')}
      >
        <img src={iconPencil} alt="" />
      </div>
      <div className="cc-top">
        <SocialMedias socialMedias={contact.socialMedias} />
      </div>
      <div className="cc-left cp-main">
        <PhoneNumbers phoneNumbers={contact.phones} />
        <Emails emails={contact.emails} />
        <Birthday
          birthdayDay={contact.birthday.day}
          birthdayMonth={contact.birthday.month}
          birthdayYear={contact.birthday.year}
        />
        <Tags
          tags={contact.groups}
          values={groupData}
          onChange={() => {}}
          element={(groupID) => {
            const group = groupData.find((g) => g.id === groupID);
            return (
              <>
                <ProfilePicture
                  name={group.name}
                  colour={group.colour}
                  image={group.image}
                  width="28px"
                  height="28px"
                />
                {group.name}
              </>
            );
          }}
          text=""
          searchKeys={[]}
          linkStub="/group/"
          editing={false}
        />
      </div>
      <div className="cc-right" style={{ paddingTop: (contact.socialMedias.filter(Boolean).length === 0 ? '30px' : 0) }}>
        <BoxToggle boxContent={boxContent} />
      </div>
    </div>
  );
}
