import { useViewport } from '../../../../api/ViewportContext';
import {
  useSortedContacts, useSortedContactsUpdate, getSortAttributes, getSortDirections,
} from '../SortingContext';
import dropdownIcon from '../../../../images/icon-dropdown.png';

const sortDirections = getSortDirections();
const sortAttributes = getSortAttributes();

const LC_STATES = [
  {
    ATTR: sortAttributes.LAST_CONTACTED.val,
    DIR: sortDirections.ASCENDING,
  },
  {
    ATTR: sortAttributes.LAST_CONTACTED.val,
    DIR: sortDirections.DESCENDING,
  },
];

const dayFormat = new Intl.DateTimeFormat(window.navigator.language, { day: 'numeric' });
const monthFormat = new Intl.DateTimeFormat(window.navigator.language, { month: 'short' });
const yearFormat = new Intl.DateTimeFormat(window.navigator.language, { year: 'numeric' });

function getColour(lastContactedDays, frequencyGoal) {
  if (frequencyGoal === 0) { return '#aaa'; }
  if (lastContactedDays < frequencyGoal) {
    return '#44d462';
  }
  return '#ed9c21';
}

export default function LastContactedAttr({ props }) {
  const { contact, header } = props;
  const { isMobile } = useViewport();
  const { updateSortData } = useSortedContactsUpdate();

  if (header) {
    const { sortData } = useSortedContacts();
    const index = LC_STATES.findIndex((state) => (
      state.ATTR === sortData.ATTR && state.DIR === sortData.DIR
    ));

    if (index > -1) {
      return (
        <div className="attr-header attr-last-contacted">
          <div
            role="button"
            tabIndex="0"
            onClick={() => updateSortData(LC_STATES[(index + 1) % LC_STATES.length])}
            onKeyUp={(e) => e.key === 'Enter' && updateSortData(LC_STATES[(index + 1) % LC_STATES.length])}
          >
            Last Contacted
            <img
              src={dropdownIcon}
              alt=""
              style={
                {
                  transform: 'rotate(' + (sortData.DIR === sortDirections.ASCENDING ? '-90' : '90') + 'deg)',
                }
              }
            />
          </div>
        </div>
      );
    }

    return (
      <div
        className="attr-header attr-last-contacted"
        role="button"
        tabIndex="0"
        onClick={() => updateSortData(LC_STATES[0])}
        onKeyUp={(e) => e.key === 'Enter' && updateSortData(LC_STATES[0])}
      >
        Last Contacted
      </div>
    );
  }

  const lastContactedDays = parseInt((new Date() - new Date(contact.lastContacted))
    / (60 * 60 * 1000 * 24), 10);

  const lastCont = new Date(contact.lastContacted);
  const formattedDate = [
    parseInt(yearFormat.format(lastCont), 10) !== new Date().getFullYear()
      ? [] : dayFormat.format(lastCont),
    monthFormat.format(lastCont),
    parseInt(yearFormat.format(lastCont), 10) === new Date().getFullYear()
      ? [] : yearFormat.format(lastCont),
  ].join(' ');

  return (
    <div
      className="attr-container attr-last-contacted"
    >
      <span
        style={{
          border: (isMobile ? '2px solid ' + getColour(lastContactedDays, contact.frequencyGoal) : 'none'),
          borderRadius: '15px',
          lineHeight: (isMobile ? '23px' : 'auto'),
        }}
      >
        {contact.lastContacted === null ? '' : formattedDate}
      </span>
    </div>
  );
}
