import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { groupBy } from '../../common/functions';
import { useContactData } from '../../../api/ContactDataContext';

const sortedContactsContext = createContext([]);
const sortedContactsUpdateContext = createContext([]);

const SORT_ATTRS = {
  NAME:
  {
    val: 'name',
    func: (contacts, groups, dir) => sortByName(contacts, groups, dir),
  },
  LAST_NAME:
  {
    val: 'lastName',
    func: (contacts, groups, dir) => sortByLastName(contacts, groups, dir),
  },
  BIRTHDAY:
  {
    val: 'birthday',
    func: (contacts, groups, dir) => sortByBirthday(contacts, groups, dir),
  },
  LAST_CONTACTED:
  {
    val: 'lastContacted',
    func: (contacts, groups, dir) => sortByLastContacted(contacts, groups, dir),
  },
  GOAL_STATUS:
  {
    val: 'goalStatus',
    func: (contacts, groups, dir) => sortByGoalStatus(contacts, groups, dir),
  },
};

const SORT_DIR = {
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
};

const CONTACTED_GROUPS = [
  {
    val: 3,
    text: 'Last 3 days',
  },
  {
    val: 7,
    text: 'Last week',
  },
  {
    val: 14,
    text: 'Last 2 weeks',
  },
  {
    val: 28,
    text: 'Last 28 days',
  },
  {
    val: 90,
    text: 'Last 3 months',
  },
  {
    val: 180,
    text: 'Last 6 months',
  },
];

export function getSortAttributes() {
  return SORT_ATTRS;
}

export function getSortDirections() {
  return SORT_DIR;
}

function joinNames(fname, lname) {
  return [fname, lname].filter(Boolean).join(' ');
}

function sortByName(contacts, group, dir) {
  const contactsInGroup = (group ? contacts.filter((c) => c.groups.includes(group.id)) : contacts);
  const groupedContacts = groupBy(contactsInGroup,
    (c) => joinNames(c.firstName, c.lastName).charAt(0).toUpperCase());
  const sortedByLetter = (dir === SORT_DIR.ASCENDING ? Array.from(groupedContacts).sort()
    : Array.from(groupedContacts).sort().reverse());
  const sortedByName = sortedByLetter.map(([letter, names]) => {
    const sortedNames = names.sort((n1, n2) => joinNames(n1.firstName, n1.lastName).toUpperCase()
      .localeCompare(joinNames(n2.firstName, n2.lastName).toUpperCase()));
    return [letter, (dir === SORT_DIR.ASCENDING ? sortedNames : sortedNames.reverse())];
  });
  return sortedByName;
}

function sortByLastName(contacts, group, dir) {
  const contactsInGroup = (group ? contacts.filter((c) => c.groups.includes(group.id)) : contacts);
  const groupedContacts = groupBy(contactsInGroup, (c) => (c.lastName ?? '#').charAt(0).toUpperCase());
  const sortedByLetter = (
    dir === SORT_DIR.ASCENDING ? Array.from(groupedContacts).sort()
      : Array.from(groupedContacts).sort().reverse()
  );
  const sortedByName = sortedByLetter.map(([letter, names]) => {
    const sortedNames = names.sort((n1, n2) => (
      n1.lastName.toUpperCase().localeCompare(n2.lastName.toUpperCase())
    ));
    return [
      letter,
      (dir === SORT_DIR.ASCENDING ? sortedNames : sortedNames.reverse()),
    ];
  });
  return sortedByName;
}

function getYear(day, month, dir) {
  const year = new Date().getFullYear();
  if (dir === SORT_DIR.DESCENDING) {
    return (new Date(year, month - 1, day).setHours(0, 0, 0)
      <= new Date().setHours(0, 0, 0) ? year : year - 1);
  }
  return (new Date(year, month - 1, day).setHours(0, 0, 0)
    >= new Date().setHours(0, 0, 0) ? year : year + 1);
}

function sortByBirthday(contacts, group, dir) {
  const contactsInGroup = (group ? contacts.filter((c) => c.groups.includes(group.id)) : contacts);
  const noBirthdayContacts = contactsInGroup.filter((c) => !c.birthday.day || !c.birthday.month);
  const birthdayContacts = contactsInGroup.filter((c) => c.birthday.day && c.birthday.month);
  const groupedByBirthday = groupBy(birthdayContacts, (c) => ([getYear(c.birthday.day, c.birthday.month, dir), c.birthday.month].filter(Boolean).join('-')));
  const sortedMonths = Array.from(groupedByBirthday).sort((d1, d2) => {
    const splitDate1 = d1[0].split('-');
    const splitDate2 = d2[0].split('-');
    return (dir === SORT_DIR.DESCENDING ? new Date(splitDate2[0], splitDate2[1], 1)
      - new Date(splitDate1[0], splitDate1[1], 1) : new Date(splitDate1[0], splitDate1[1], 1)
      - new Date(splitDate2[0], splitDate2[1], 1));
  });
  const sortedBirthdays = sortedMonths.map(([key, c]) => {
    const currYear = new Date().getFullYear();
    const [year, month] = key.split('-');
    const newKey = new Date(year, month - 1, 1).toLocaleDateString(
      window.navigator.language, currYear === parseInt(year, 10) ? { month: 'long' } : { month: 'long', year: 'numeric' },
    );
    const sortedContacts = c.sort((c1, c2) => {
      if (dir === SORT_DIR.DESCENDING) {
        return (new Date(currYear, c2.birthday.month, c2.birthday.day)
          - new Date(currYear, c1.birthday.month, c1.birthday.day));
      }
      return (new Date(currYear, c1.birthday.month, c1.birthday.day)
        - new Date(currYear, c2.birthday.month, c2.birthday.day));
    });
    return [newKey, sortedContacts];
  });
  if (noBirthdayContacts.length > 0) {
    sortedBirthdays.push(['No Birthday', noBirthdayContacts]);
  }

  return sortedBirthdays;
}

function getContactedGroup(contact) {
  const lastContactedDays = parseInt((new Date() - new Date(contact.lastContacted))
    / (60 * 60 * 1000 * 24), 10);
  const group = CONTACTED_GROUPS.find((g) => g.val >= lastContactedDays);
  return group ?? { val: 360, text: 'More than 6 months ago' };
}

function sortByLastContacted(contacts, group, dir) {
  const contactsInGroup = (group ? contacts.filter((c) => c.groups.includes(group.id)) : contacts);
  const notContacted = contactsInGroup.filter((c) => !c.lastContacted);
  const contacted = contactsInGroup.filter((c) => c.lastContacted);
  const groupedByLastContacted = groupBy(contacted, (c) => getContactedGroup(c));
  const sorted = Array.from(groupedByLastContacted).sort((g1, g2) => (
    dir === SORT_DIR.ASCENDING ? g1[0].val - g2[0].val : g2[0].val - g1[0].val
  ));
  const sortedByLastContacted = sorted.map(([g, c]) => {
    const sortedContacts = c.sort((c1, c2) => (
      dir === SORT_DIR.ASCENDING ? new Date(c2.lastContacted)
        - new Date(c1.lastContacted) : new Date(c1.lastContacted) - new Date(c2.lastContacted)
    ));
    return [g.text, sortedContacts];
  });
  if (notContacted.length > 0) {
    sortedByLastContacted.push(['Not contacted', notContacted]);
  }
  return sortedByLastContacted;
}

function getGoalStatus(contact, dir) {
  if (contact.frequencyGoal === 0) return (dir === SORT_DIR.ASCENDING ? 3 : 0);
  const lastContactedDays = parseInt((new Date() - new Date(contact.lastContacted))
    / (60 * 60 * 1000 * 24), 10);
  if (lastContactedDays < contact.frequencyGoal) {
    return 1;
  }
  return 2;
}

function sortByGoalStatus(contacts, group, dir) {
  const sectionText = ['No Goal Set', 'Healthy', 'Needs Attention'];
  const contactsInGroup = (group ? contacts.filter((c) => c.groups.includes(group.id)) : contacts);
  const notContacted = contactsInGroup.filter((c) => !c.lastContacted);
  const contacted = contactsInGroup.filter((c) => c.lastContacted);
  const groupedByGoalStatus = groupBy(contacted, (c) => getGoalStatus(c, dir));
  const sorted = Array.from(groupedByGoalStatus).sort((g1, g2) => (
    dir === SORT_DIR.ASCENDING ? g1[0] - g2[0] : g2[0] - g1[0]
  ));
  const sortedByGoalStatus = sorted.map(([g, c]) => {
    const sortedContacts = c.sort((c1, c2) => {
      const lcd1 = parseInt((new Date() - new Date(c1.lastContacted))
      / (60 * 60 * 1000 * 24), 10);
      const lcd2 = parseInt((new Date() - new Date(c2.lastContacted))
      / (60 * 60 * 1000 * 24), 10);
      return c1.frequencyGoal - lcd1 - (c2.frequencyGoal - lcd2);
    });
    return [sectionText[g % 3], sortedContacts];
  });
  if (notContacted.length > 0) {
    sortedByGoalStatus.push(['Not contacted', notContacted]);
  }
  return sortedByGoalStatus;
}

export default function SortedDataProvider({ children }) {
  const [sortAttr, setSortAttr] = useState(SORT_ATTRS.NAME);
  const [sortDir, setSortDir] = useState(SORT_DIR.ASCENDING);
  const [group, setGroup] = useState(null);
  const { contactData } = useContactData();

  const [sortedContactData, setSortedContactData] = useState(
    sortAttr.func(contactData ?? [], group, sortDir),
  );

  function updateSortData(data) {
    setSortAttr(Object.values(SORT_ATTRS).find((attr) => attr.val === data.ATTR));
    setSortDir(data.DIR);
  }

  function updateGroup(groupObject) {
    setGroup(groupObject);
  }

  useEffect(() => {
    setSortedContactData(sortAttr.func(contactData ?? [], group, sortDir));
  }, [sortAttr, sortDir, group, contactData]);

  return (
    <sortedContactsUpdateContext.Provider value={{ updateSortData, updateGroup }}>
      <sortedContactsContext.Provider value={{ sortAttr, sortDir, sortedContactData }}>
        {children}
      </sortedContactsContext.Provider>
    </sortedContactsUpdateContext.Provider>
  );
}

export function useSortedContacts() {
  const { sortAttr, sortDir, sortedContactData } = useContext(sortedContactsContext);
  return { sortData: { ATTR: sortAttr.val, DIR: sortDir }, sortedContactData };
}

export function useSortedContactsUpdate() {
  const { updateSortData, updateGroup } = useContext(sortedContactsUpdateContext);
  return { updateSortData, updateGroup };
}
