import React, { memo, useRef } from 'react';
import './NavBarDesktop.scss';

import { ChangeThemeButton } from '../../common/buttons/ChangeThemeButton';
import { SearchBox } from '../../common/SearchBox';
import MenuItems from './MenuItems';

import minimiseIcon from '../../../images/icon-minimise.png';

// HELPER FUNCTIONS //

/**
 * Toggles if navbar is minimised or not
 * @param {object} event  click event
 */
function toggleMinimisedNav(navbar) {
  const root = document.documentElement;
  if (navbar.classList.contains('nav-bar-desktop')) {
    navbar.classList.remove('nav-bar-desktop');
    navbar.classList.add('nav-bar-desktop-min');
    root.style.setProperty('--navbar-width', '50px');
  } else {
    navbar.classList.add('nav-bar-desktop');
    navbar.classList.remove('nav-bar-desktop-min');
    root.style.setProperty('--navbar-width', '300px');
  }
}

// MAIN EXPORT //

function NavBar() {
  const navbarRef = useRef();
  const navbarClass = 'nav-bar-desktop';
  document.documentElement.style.setProperty('--navbar-width', '300px');
  return (
    <div id="navbar-main" className={navbarClass} ref={navbarRef}>
      <div
        className="nav-minimise"
        onClick={() => toggleMinimisedNav(navbarRef.current)}
        onKeyUp={(e) => (e.key === 'Enter') && toggleMinimisedNav(navbarRef.current)}
        role="button"
        tabIndex="0"
      >
        <img src={minimiseIcon} alt="" />
      </div>
      <div className="main-menu-search">
        <SearchBox width="80%" height="45px" fontSize="20px" />
      </div>
      <MenuItems />
      <nav className="secondary-menu">
        <ChangeThemeButton />
      </nav>
    </div>
  );
}

export default memo(NavBar);
