import { useEffect, useState } from 'react';
import { DateTimePicker } from 'react-rainbow-components';
import { useContactDataUpdate } from '../../../../api/ContactDataContext';
import InteractionCard from './InteractionCard';
import { SlidingButton } from '../../../common/SlidingButton';

import { getInteractionTypes } from '../../../common/interactionTypes';

import iconDropdown from '../../../../images/icon-dropdown.png';

import './Interactions.scss';

export default function Interactions({
  frequencyGoal, setFrequencyGoal, communicationHistory, setCommunicationHistory, contact, page,
}) {
  const currDateTime = new Date();
  const [date, setDate] = useState(currDateTime);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('Phone');
  const [notes, setNotes] = useState('');
  const [newCommunicationHistory, setNewCommunicationHistory] = useState(communicationHistory);
  const [newFrequencyGoal, setNewFrequencyGoal] = useState(frequencyGoal);
  const [typePopup, openTypePopup] = useState(false);

  const { updateContactById } = useContactDataUpdate();

  let pastCommunicationHistory = newCommunicationHistory.filter((c) => new Date(c.date)
    - currDateTime <= 0);
  const futureCommunicationHistory = newCommunicationHistory.filter((c) => new Date(c.date)
    - currDateTime > 0);

  pastCommunicationHistory = pastCommunicationHistory.sort((c1, c2) => new Date(c1.date)
    - new Date(c2.date)).slice(pastCommunicationHistory.length <= 3 ? 0
    : pastCommunicationHistory.length - 3);

  const handleSubmitCommunication = () => {
    const updatedContact = contact;
    const newCommunication = {
      type,
      date: date.toISOString(),
      note: notes,
    };

    updatedContact.communicationHistory = [...newCommunicationHistory, newCommunication];
    if (pastCommunicationHistory.length > 1) {
      updatedContact.lastContacted = pastCommunicationHistory.sort((c1, c2) => new Date(c2.date)
        - new Date(c1.date))[0].date;
    } else {
      updatedContact.lastContacted = (
        new Date(pastCommunicationHistory[0]) < new Date(contact.lastContacted)
          ? pastCommunicationHistory[0] : contact.lastContacted
      );
    }

    if (date - new Date() < 0 && date > new Date(contact.lastContacted)) {
      updatedContact.lastContacted = date;
    }

    setNotes('');
    setDate(new Date());

    updateContactById(contact.id, updatedContact);
    setCommunicationHistory([...newCommunicationHistory, newCommunication]);
    setNewCommunicationHistory([...newCommunicationHistory, newCommunication]);
  };

  useEffect(() => {
    const updatedContact = contact;
    updatedContact.frequencyGoal = newFrequencyGoal;

    setFrequencyGoal(newFrequencyGoal);

    if (newFrequencyGoal !== frequencyGoal || page === 'contacts') {
      updateContactById(contact.id, updatedContact);
    }
  }, [newFrequencyGoal]);

  function dropDown() {
    return (
      <>
        <select
          name="days"
          value={newFrequencyGoal}
          onChange={(e) => setNewFrequencyGoal(e.target.value)}
        >
          <option value="3">3 days</option>
          <option value="7">7 days</option>
          <option value="14">14 days</option>
          <option value="30">30 days</option>
          <option value="60">60 days</option>
          <option value="120">120 days</option>
        </select>
      </>
    );
  }

  const frequencyGoalMessage = () => {
    const lastContactedDays = parseInt((new Date()
      - new Date(contact.lastContacted)) / (60 * 60 * 1000 * 24), 10);

    if (contact.lastContacted === null) {
      return (
        <p>You haven&apos;t recorded any interactions yet</p>
      );
    }

    return (
      <div>
        <h4>
          Goal status:
          {lastContactedDays < newFrequencyGoal
            ? (
              <span style={{ color: '#16c728' }}> Healthy</span>
            )
            : (
              <span style={{ color: '#ffa51f' }}> Needs Attention</span>
            )}
        </h4>
        <p>
          {lastContactedDays < newFrequencyGoal
            ? (
              `You last had contact ${lastContactedDays} days ago`
            )
            : (
              `You last had contact ${lastContactedDays} days ago`
            )}
        </p>
      </div>
    );
  };

  return (
    <div className="cp-interactions-container">
      <div className="cp-frequency-goal">
        <SlidingButton
          position={newFrequencyGoal !== 0}
          onChange={() => setNewFrequencyGoal(newFrequencyGoal === 0 ? 7 : 0)}
        />
        { newFrequencyGoal === 0
          ? (
            <p>No frequency goal set</p>
          )
          : (
            <>
              <div>
                <p>
                  Set frequency goal for every&nbsp;
                  {dropDown()}
                </p>
              </div>
              {newFrequencyGoal !== 0
            && (
              <div className="cp-frequency-message">
                {frequencyGoalMessage()}
              </div>
            )}
            </>
          )}
      </div>
      <div className="cp-interactions-add">
        {open
        && (
          <div className="cp-interactions-flex">
            <div
              className="cp-interactions-type"
              role="button"
              tabIndex="0"
              onClick={() => openTypePopup(!typePopup)}
              onKeyUp={(e) => e.key === 'Enter' && openTypePopup(!typePopup)}
            >
              <img className="cp-interactions-type-img" src={getInteractionTypes()[type]} alt="" />
              <img className="cp-interactions-dropdown" src={iconDropdown} alt="" />
              {typePopup
              && (
                <div className="cp-interactions-select-type">
                  {Object.entries(getInteractionTypes()).map((interactionType) => (
                    <div
                      key={interactionType[0]}
                      className="cp-interactions-dropdown-type"
                      role="button"
                      tabIndex="0"
                      onClick={() => { setType(interactionType[0]); openTypePopup(false); }}
                      onKeyUp={(e) => { if (e.key === 'Enter') { setType(interactionType[0]); openTypePopup(false); } }}
                    >
                      <img src={interactionType[1]} alt="" />
                      <span>{interactionType[0]}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <textarea
              placeholder="Notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              maxLength="100"
              rows="2"
            />
            <DateTimePicker value={date} onChange={setDate} className="cp-interactions-dtp" />
          </div>
        )}

        <div
          className="cp-interactions-submit"
          role="button"
          tabIndex="0"
          onClick={() => {
            if (open) {
              handleSubmitCommunication();
            }
            setOpen(true);
          }}
          onKeyUp={(e) => {
            if (e.key !== 'Enter') return;
            if (open) {
              handleSubmitCommunication();
            }
            setOpen(true);
          }}
        >
          <span>
            {open ? 'Add' : 'New'}
            {' '}
            Interaction
          </span>
        </div>
      </div>
      {futureCommunicationHistory.length > 0
        && (
          <div className="cp-interactions-future">
            <h3>Upcoming</h3>
            {futureCommunicationHistory.map((c, i) => (
              <InteractionCard
                key={i}
                type={c.type}
                date={c.date}
                note={c.note}
              />
            ))}
          </div>
        )}
      <div className="cp-interactions-past">
        <h3>History</h3>
        {pastCommunicationHistory.length > 0
          ? (
            <>
              {pastCommunicationHistory.reverse().map((c, i) => (
                <InteractionCard
                  key={i}
                  type={c.type}
                  date={c.date}
                  note={c.desc}
                />
              ))}
            </>
          )
          : (
            <p style={{ margin: 15 }}>No recorded interactions</p>
          )}
      </div>

    </div>
  );
}
