import { useState } from 'react';
import { Redirect } from 'react-router';
import { useEventData } from '../../../../api/EventDataContext';
import { getColor } from '../../../common/functions';

import './Notifications.scss';

export default function Notifications() {
  const [redirect, setRedirect] = useState(null);
  const currDate = new Date();

  const { eventData } = useEventData();

  const upcomingEvents = eventData
    .filter((event) => (new Date(event.start) - currDate > 0
      && (new Date(event.start) - currDate) / (24 * 1000 * 60 * 60) < 7))
    .sort((e1, e2) => new Date(e1.start) - new Date(e2.start));

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  return (
    <div className="dropdown-notifications">
      {
      upcomingEvents.length > 0
        ? (
          <>
            <h3>Upcoming Events</h3>
            {upcomingEvents.map((event) => (
              <div
                key={event.id}
                className="notification-event"
                role="button"
                tabIndex="0"
                onClick={() => setRedirect('/event/' + event.urlString)}
                onKeyDown={(e) => e.key === 'Enter' && setRedirect('/event/' + event.urlString)}
              >
                <div className="notification-event-nub" style={{ backgroundColor: getColor(event.type) }} />
                <span>{event.name}</span>
                <span>
                  {(new Date(event.start)).toLocaleDateString(window.navigator.language, {
                    month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit',
                  })}
                </span>
              </div>
            ))}
          </>
        )
        : (
          <h3>
            No new notifications
          </h3>
        )

      }
    </div>
  );
}
