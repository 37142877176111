import { useState } from 'react';
import { useContactData, useContactDataUpdate } from '../../api/ContactDataContext';

import { useGroupData, useGroupDataUpdate } from '../../api/GroupDataContext';

import { ProfilePicture } from '../common/ProfilePicture';
import { urlUniqueFriendlyString } from '../common/functions';
import { Tags } from '../common/Tags';
import './AddGroup.scss';

export default function AddGroup({ closeUI }) {
  const [groupName, setGroupName] = useState('');
  const [contacts, setContacts] = useState([]);
  const { addGroup } = useGroupDataUpdate();
  const { groupData } = useGroupData();
  const { contactData } = useContactData();
  const { updateContactById } = useContactDataUpdate();
  const [image, setImage] = useState('');
  const [colour, setColour] = useState('');

  const handleSubmit = () => {
    if (groupName.length === 0) {
      return;
    }
    const newGroup = {
      id: null,
      name: groupName,
      urlString: urlUniqueFriendlyString(groupName, groupData.map((g) => g.urlString)),
      image,
      colour,
    };
    addGroup(newGroup)
      .then((groupId) => {
        contacts.map((contactId) => contactData
          .find((contact) => contact.id === contactId)).forEach((contact) => {
          const newContactData = contact;
          newContactData.groups = [...new Set([...contact.groups, groupId])];
          updateContactById(contact.id, newContactData);
        });
        closeUI();
      });
  };

  // TODO: Add ability to select contacts to add to group
  return (
    <div className="ag-container">
      <div className="ag-pic">
        <ProfilePicture
          name={groupName || 'New Group'}
          width="120px"
          height="120px"
          edit
          editFuncs={{ setColour, setImage, setFormImage: () => {} }}
          colour={colour}
          image={image}
        />
        <h3>Click to modify</h3>
      </div>
      <div className="ag-group-create">
        <input
          className="ag-group-name"
          type="text"
          name="group-name"
          placeholder="New Group Name"
          onChange={(e) => setGroupName(e.target.value)}
        />
        <Tags
          tags={contacts}
          values={contactData}
          onChange={(newContacts) => setContacts(newContacts)}
          element={(id) => {
            const contact = contactData.find((c) => c.id === id);
            return (
              <>
                <ProfilePicture
                  fname={contact.firstName}
                  lname={contact.lastName}
                  colour={contact.colour}
                  image={contact.image}
                  width="28px"
                  height="28px"
                  initialCount={2}
                  contact
                />
                {[contact.firstName, contact.lastName].filter(Boolean).join(' ')}
              </>
            );
          }}
          text="Add Contacts to Group"
          searchKeys={['firstName', 'lastName']}
          editing
        />
        <div
          className="ag-submit"
          type="button"
          value="Create Group"
          role="button"
          tabIndex="0"
          onClick={() => handleSubmit()}
          onKeyUp={(e) => e.key === 'Enter' && handleSubmit()}
        >
          <span>Create Group</span>
        </div>
      </div>
    </div>
  );
}
