import { useState, useEffect } from 'react';
import { useViewport } from '../../../../api/ViewportContext';

export default function Emails({ emails, setEmails, editing }) {
  const [iconsVisible, setIconsVisible] = useState(-1);
  const { isMobile } = useViewport();
  const emaiLCount = emails.filter((e) => e !== '').length;

  useEffect(() => {}, []);
  if (emaiLCount === 0 && !editing) { return (<></>); }
  return (
    <div
      className={'cp-list' + (editing ? '' : ' cp-box')}
      style={
        {
          height: emails.filter((e) => {
            if (editing) {
              return true;
            }
            return (e !== '');
          }).length * (editing ? 48 : 38) + (editing ? 40 : 35),
        }
      }
    >
      <h3 className="cp-field-label">{'Email' + (emaiLCount <= 1 ? '' : 's')}</h3>
      {emails.map((email, i) => (
        <div
          className="cp-list-entry"
          style={{ height: '38px' }}
          key={i}
          onMouseEnter={() => setIconsVisible(i)}
          onMouseLeave={() => setIconsVisible(-1)}
        >
          {(!editing && email === '') || (
            <div key={i}>
              {(!editing && (iconsVisible === i || isMobile)) && (
                <>
                  <input
                    type="button"
                    className="cp-email-icon"
                    onClick={() => window.open('mailto:' + email)}
                    title="Click to compose email"
                  />
                </>
              )}
              <input
                key={i}
                readOnly={!editing}
                autoComplete="off"
                type="text"
                name="email"
                value={email}
                title={email}
                placeholder="Email"
                onChange={(e) => {
                  emails.splice(i, 1, e.target.value);
                  setEmails([...emails]);
                }}
                style={{
                  width: ((i === emails.length - 1) && editing) ? 'calc(100% - 44px)' : '100%',
                }}
              />
              {(i === emails.length - 1) && editing && (
                <div
                  className="cp-add-entry"
                  role="button"
                  tabIndex="0"
                  onClick={(e) => { if (emails[i] !== '') { setEmails(emails.concat([''])); e.stopPropagation(); } }}
                  onKeyUp={(e) => (emails[i] !== '') && e.key === 'Enter' && setEmails(emails.concat(['']))}
                >
                  +
                </div>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
