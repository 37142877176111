/* eslint-disable no-param-reassign */
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';

import axios from 'axios';
import { reauthenticate, BASE_URL } from '.';

const eventDataContext = createContext([]);
const eventDataUpdateContext = createContext([]);

export default function EventDataProvider({ children }) {
  const [eventData, setEventData] = useState(null);

  useEffect(() => {
    getEvents();
  }, []);

  function getEvents() {
    axios
      .get(`${BASE_URL}/events`)
      .then((res) => {
        setEventData(res.data);
      })
      .catch(() => {
        reauthenticate().then(() => getEvents());
      });
  }

  function addEvent(eventObject) {
    return (
      axios
        .post(`${BASE_URL}/events`, { ...eventObject })
        .then((res) => {
          eventObject.id = res.data;
          const updatedEvents = eventData.concat(eventObject);
          setEventData(updatedEvents);
        })
        .catch(() => {
          reauthenticate().then(() => addEvent(eventObject));
        })
    );
  }

  function updateEventById(id, eventObject) {
    const updatedEvents = eventData.map((e) => (e.id === id ? eventObject : e));
    setEventData(updatedEvents);

    axios
      .put(`${BASE_URL}/events`, { eventID: id, fields: { ...eventObject } })
      .catch(() => {
        reauthenticate().then(() => updateEventById(id, eventObject));
      });
  }

  function removeContactFromEvents(contactId) {
    const updatedEvents = eventData.map((event) => {
      const updatedEvent = event;
      const newContacts = event.contacts;
      updatedEvent.contacts = newContacts.filter((c) => c !== contactId);
      return updatedEvent;
    });
    setEventData(updatedEvents);
  }

  function deleteEvent(id) {
    const updatedEvents = eventData.filter((e) => e.id !== id);
    setEventData(updatedEvents);
    axios
      .post(`${BASE_URL}/events/delete`, { eventID: id })
      .catch(() => reauthenticate().then(() => deleteEvent(id)));
  }

  return (
    <eventDataUpdateContext.Provider value={{
      addEvent, updateEventById, deleteEvent, removeContactFromEvents,
    }}
    >
      <eventDataContext.Provider value={{ eventData }}>
        {children}
      </eventDataContext.Provider>
    </eventDataUpdateContext.Provider>
  );
}

export function useEventData() {
  const { eventData } = useContext(eventDataContext);
  return { eventData };
}

export function useEventDataUpdate() {
  const {
    addEvent, updateEventById, deleteEvent, removeContactFromEvents,
  } = useContext(eventDataUpdateContext);
  return {
    addEvent, updateEventById, deleteEvent, removeContactFromEvents,
  };
}
