import React, { memo } from 'react';
import './Header.scss';
import mainLogo from '../../../images/logo-main.png';

function HeaderMobile() {
  return (
    <div className="header header-mobile">
      <img id="logo-img" src={mainLogo} alt="Phonebook plus" />
      <h1 id="app-title">Phonebook+</h1>
    </div>
  );
}

export default memo(HeaderMobile);
