import { useState } from 'react';
import { SlidingButton } from '../../SlidingButton';
import './ChangeThemeButton.scss';

/**
 * Called to toggle the current theme
 * Changes the stylesheet referenced in head
 */
function ToggleTheme() {
  const theme = document.getElementById('theme_css').href;
  const themeDir = theme.split('/');
  if (themeDir[themeDir.length - 1] === 'light.css') {
    document.getElementById('theme_css').href = '/styles/themes/dark.css';
    localStorage.setItem('theme', 'dark');
  } else {
    document.getElementById('theme_css').href = '/styles/themes/light.css';
    localStorage.setItem('theme', 'light');
  }
}

/**
 * Checks which theme is currently applied and positions button toggle
 * accordingly
 * @returns {string} 'left' or 'right'
 */
function GetPositionFromTheme() {
  const theme = document.getElementById('theme_css').href;
  const themeDir = theme.split('/');
  return (themeDir[themeDir.length - 1] === 'dark.css');
}

export default function ChangeThemeButton() {
  const [darkTheme, setDarkTheme] = useState(GetPositionFromTheme());
  return (
    <div className="nav-change-theme">
      <div
        onClick={() => ToggleTheme()}
        onKeyUp={(e) => (e.key === 'Enter') && ToggleTheme()}
        role="button"
        tabIndex="0"
      >
        <SlidingButton position={darkTheme} onChange={() => setDarkTheme(!darkTheme)} />
      </div>
      <h4>Dark Mode</h4>
    </div>
  );
}
