import './InteractionCard.scss';
import { getInteractionTypes } from '../../../common/interactionTypes';

export default function InteractionCard({ type, date, note }) {
  return (
    <div className="cp-interaction-card">
      <div className="type">
        <img src={getInteractionTypes()[type]} alt="" />
      </div>
      <p className="date">
        {new Date(date).toLocaleDateString(window.navigator.language, { day: 'numeric', month: 'short' })}
      </p>
      <p className="notes">
        {note && note.length > 0 ? note : 'No notes'}
      </p>
    </div>
  );
}
