import { useRef } from 'react';
import PropTypes from 'prop-types';
import { useInteractionBoxUpdate } from '../../../api/InteractionBoxContext';
import { getColor, hashString } from '../functions';
import EditIcon from './EditIcon';
import './ProfilePicture.scss';

export default function ProfilePicture(props) {
  const {
    name,
    fname,
    lname,
    width,
    height,
    initialCount,
    contact,
    edit,
    editFuncs,
    colour,
    image,
  } = props;
  const { addInteractionBox } = useInteractionBoxUpdate();
  const containerRef = useRef(null);
  const bgColor = colour || getColor(name !== '' ? name : fname + ' ' + lname);
  const hashValue = hashString((name !== '' ? name : fname + ' ' + lname) + bgColor);
  let initials = 'n';

  if (name !== '') {
    initials = name.split(' ').map((i) => (i ? i[0] : [])).join('').substring(0, initialCount);
  } else {
    initials = ((fname?.length > 0 ? fname[0] : '') + (lname?.length > 0 ? lname[0] : ''));
  }

  const propertyParts = height.match(/^(\d+(?:\.\d+)?)(.*)$/);

  const openEdit = () => {
    addInteractionBox((closeUI) => (
      <EditIcon
        editFuncs={editFuncs}
        profilepic={
          (tempColour, tempImage) => (
            <ProfilePicture
              name={name}
              fname={fname}
              lname={lname}
              width="101px"
              height="101px"
              initialCount={initialCount}
              colour={tempColour}
              image={tempImage}
              contact={contact}
            />
          )
        }
        closeUI={() => closeUI()}
        currColour={colour}
        currImage={image}
      />
    ),
    '400px',
    '400px');
  };

  return (
    <div
      className="profile-pic-container"
      ref={containerRef}
      style={
        {
          backgroundColor: image ? '#fff0' : bgColor,
          backgroundImage: `url(${image})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          borderRadius: contact ? 100 : '20%',
          width,
          height,
        }
      }
    >
      <span
        style={
          {
            fontSize: propertyParts[1] * (contact ? 0.5 : 0.75) + propertyParts[2],
          }
        }
      >
        {!image && initials.toUpperCase()}
      </span>
      {!image
      && (
        <div
          className="background-gradient"
          style={
            {
              top: ((hashValue % 57)) + '%',
              left: ((hashValue % 47)) + '%',
              transform: 'rotate(' + (hashValue % 146) + 'deg)',
              background: 'linear-gradient(to bottom right, white, #ccc5)',
            }
          }
        />
      )}
      {edit
      && (
        <div
          className="profile-pic-edit"
          style={
            {
              borderRadius: contact ? 100 : '20%',
              fontSize: propertyParts[1] * 0.25 + propertyParts[2],
            }
          }
          role="button"
          tabIndex="0"
          onClick={() => openEdit()}
          onKeyUp={(e) => e.key === 'Enter' && openEdit()}
        >
          Modify
        </div>
      )}
    </div>
  );
}

ProfilePicture.propTypes = {
  name: PropTypes.string,
  fname: PropTypes.string,
  lname: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  initialCount: PropTypes.number,
  contact: PropTypes.bool,
};

ProfilePicture.defaultProps = {
  name: '',
  fname: '',
  lname: '',
  width: '30px',
  height: '30px',
  initialCount: 1,
  contact: false,
};
