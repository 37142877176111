import './Contacts.scss';
import React, {
  useState, useReducer, Fragment, useEffect, useRef,
} from 'react';
import { Redirect } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars-2';
import { useSortedContacts, useSortedContactsUpdate } from './SortingContext';
import { useViewport } from '../../../api/ViewportContext';
import { useContactData } from '../../../api/ContactDataContext';
import ContactCard from './ContactCard';
import Contact from '../Contact/Contact';
import ContactsHeader from './ContactsHeader';
import {
  BirthdayAttr,
  LastContactedAttr,
  GoalStatusAttr,
  NameAttr,
} from './ListAttributes';
import iconExpand from '../../../images/icon-expand.png';
import iconPencil from '../../../images/icon-pencil.png';

const ATTRIBUTES = {
  name: (props) => <NameAttr props={props} />,
  birthday: (props) => <BirthdayAttr props={props} />,
  lastContacted: (props) => <LastContactedAttr props={props} />,
  goalStatus: (props) => <GoalStatusAttr props={props} />,
};

const VIEW_ACTIONS = {
  SET: 'set',
  REMOVE: 'remove',
  UPDATE: 'update',
  SAVE: 'save',
};

const VIEWS = {
  NONE: 'none',
  SIDE: 'side',
  EXPANDED: 'expanded',
  MOBILE: 'mobile',
};

const CHECKED_ACTIONS = {
  ALL: 'all',
  NONE: 'none',
  ONE: 'one',
  NEW: 'new',
};

function setViewContact(state, action) {
  switch (action.type) {
    case VIEW_ACTIONS.SAVE:
      return { contact: action.contact };
    case VIEW_ACTIONS.UPDATE:
      return { contact: state.contact };
    case VIEW_ACTIONS.SET:
      if (action.contact === state.contact) {
        return { contact: {} };
      }
      return { contact: action.contact };
    case VIEW_ACTIONS.REMOVE: {
      return { contact: {} };
    }
    default:
      return state;
  }
}

function setViewType(window) {
  if (window.isMobile) {
    return VIEWS.MOBILE;
  }
  if (window.width < 1500) {
    return VIEWS.EXPANDED;
  }
  return VIEWS.SIDE;
}

function getContacts(contactData, group) {
  const groupContacts = [];

  if (!group) {
    return contactData;
  }

  contactData.forEach((contact) => {
    contact.groups.forEach((g) => {
      if (group.id === g) {
        groupContacts.push(contact);
      }
    });
  });
  return groupContacts;
}

function setCardsChecked(state, action) {
  switch (action.type) {
    case CHECKED_ACTIONS.NEW:
      return action.contacts.map((c) => ({ id: c.id, checked: false }));
    case CHECKED_ACTIONS.ALL:
      return state.map((card) => ({ id: card.id, checked: true }));
    case CHECKED_ACTIONS.NONE:
      return state.map((card) => ({ id: card.id, checked: false }));
    case CHECKED_ACTIONS.ONE:
      return state.map((card) => (card.id === action.id
        ? { id: card.id, checked: !card.checked } : card));
    default:
      return state.map((card) => ({ id: card.id, checked: false }));
  }
}

function Contacts({ group }) {
  const [editContact, setEditContact] = useState(false);
  const scrollBarRef = useRef();
  const contactsRef = useRef();
  const { isMobile, width } = useViewport();
  const { contactData } = useContactData();
  const [viewContactState, viewContactDispatch] = useReducer(
    setViewContact, { contact: {} },
  );
  const [redirect, setRedirect] = useState(false);
  const [allCardsChecked, setAllCardsChecked] = useState(false);

  const view = Object.keys(viewContactState.contact).length > 0
    ? setViewType({ isMobile, width }) : VIEWS.NONE;
  const { sortedContactData } = useSortedContacts();
  const { updateGroup } = useSortedContactsUpdate();
  const groupContacts = getContacts(contactData, group);
  const [cardsChecked, cardsCheckedDispatch] = useReducer(
    setCardsChecked,
    groupContacts.map((contact) => (
      {
        id: contact.id,
        checked: false,
      }
    )),
  );

  useEffect(() => {
    cardsCheckedDispatch({ type: CHECKED_ACTIONS.NEW, contacts: groupContacts });
  }, [group]);

  useEffect(() => {
    setEditContact(false);
    updateGroup(group);
  }, [viewContactState, group]);

  useEffect(() => {
    setAllCardsChecked(cardsChecked.every((card) => card.checked));
  }, [cardsChecked, allCardsChecked]);

  if (redirect) {
    return (
      <Redirect push to={redirect} />
    );
  }

  return (
    <div
      className="contacts-container"
      style={
        {
          marginRight: view === VIEWS.SIDE ? 610 : 0,
        }
      }
    >
      <div className="contact-list-container">
        <ContactsHeader group={group} />
        {groupContacts.length > 0 ? (
          <div className="contact-list-header">
            <span className="icon-header">Icon</span>
            {Object.values(ATTRIBUTES).map((attr, i) => (
              <Fragment key={i}>
                {attr({ contact: {}, header: true })}
              </Fragment>
            ))}
          </div>
        ) : (
          <div className="contact-list-empoty">
            <h3>
              There&apos;s no one here yet.
            </h3>
          </div>
        )}
        <div className="contacts-list" ref={contactsRef}>
          {sortedContactData.map(([attrKey, contacts]) => (
            <React.Fragment key={attrKey}>
              <div className="contacts-key">{attrKey}</div>
              {contacts.map((contact) => (
                <ContactCard
                  key={contact.id}
                  contactData={contact}
                  display={{ view, viewContactState }}
                  enums={{ VIEWS, VIEW_ACTIONS }}
                  setViewContact={viewContactDispatch}
                  attributes={ATTRIBUTES}
                  checkCard={() => cardsCheckedDispatch(
                    { type: CHECKED_ACTIONS.ONE, id: contact.id },
                  )}
                />
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
      {view === VIEWS.SIDE
      && (
        <div className="contacts-sidebar">
          {!editContact
          && (
            <div
              className="contacts-button cs-edit"
              role="button"
              tabIndex="0"
              title="Edit"
              onClick={() => setEditContact(true)}
              onKeyUp={(e) => e.key === 'Enter' && setEditContact(true)}
            >
              <img src={iconPencil} alt="" />
            </div>
          )}
          <div
            className="contacts-button cs-expand"
            role="button"
            tabIndex="0"
            title="Expand"
            onClick={() => setRedirect('/contact/' + viewContactState.contact.urlString + (editContact ? '/edit/' : ''))}
            onKeyUp={(e) => e.key === 'Enter' && setRedirect('/contact/' + viewContactState.contact.urlString + (editContact ? '/edit/' : ''))}
          >
            <img src={iconExpand} alt="" />
          </div>
          <Scrollbars
            autoHide
            ref={scrollBarRef}
          >
            <Contact
              key={viewContactState.contact.id}
              contact={viewContactState.contact}
              setEditContact={setEditContact}
              setContact={(contact) => viewContactDispatch({ type: VIEW_ACTIONS.SAVE, contact })}
              editMode={editContact}
              page="contacts"
              scrollBarRef={scrollBarRef}
            />
          </Scrollbars>
        </div>
      )}
    </div>
  );
}

export default Contacts;
