import './Buttons.scss';

import iconConfirm from '../../../images/icon-confirm.png';
import iconCancel from '../../../images/icon-cancel.png';

export default function Buttons({ confirm, cancel }) {
  return (
    <div className="cp-confirm-cancel">
      <div
        className="cp-confirm"
        role="button"
        tabIndex="0"
        onClick={() => confirm(true)}
        onKeyUp={(e) => e.key === 'Enter' && confirm(true)}
      >
        <img src={iconConfirm} alt="" />
      </div>
      <div
        className="cp-cancel"
        role="button"
        tabIndex="0"
        onClick={() => cancel(true)}
        onKeyUp={(e) => e.key === 'Enter' && cancel(true)}
      >
        <img src={iconCancel} alt="" />
      </div>
    </div>
  );
}
