/* eslint-disable no-param-reassign */
import React, {
  createContext,
  useContext,
  useState,
} from 'react';

const searchQueryContext = createContext([]);
const searchQueryUpdateContext = createContext([]);

export default function SearchQueryProvider({ children }) {
  const [searchQuery, setSearchQuery] = useState('');

  function updateSearchQuery(query) {
    setSearchQuery(query);
  }

  return (
    <searchQueryUpdateContext.Provider value={{ updateSearchQuery }}>
      <searchQueryContext.Provider value={{ searchQuery }}>
        {children}
      </searchQueryContext.Provider>
    </searchQueryUpdateContext.Provider>
  );
}

export function useSearchQuery() {
  const { searchQuery } = useContext(searchQueryContext);
  return { searchQuery };
}

export function useSearchQueryUpdate() {
  const { updateSearchQuery } = useContext(searchQueryUpdateContext);
  return { updateSearchQuery };
}
