import { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useContactDataUpdate } from '../../../api/ContactDataContext';
import { ProfilePicture } from '../../common/ProfilePicture';
import './Contact.scss';
import Buttons from './Buttons';
import { Tags } from '../../common/Tags';
import { EditDelete } from '../../common/buttons/EditDelete';
import {
  PhoneNumbers,
  Emails,
  Birthday,
  Name,
  Notes,
  SocialMedias,
  BoxToggle,
  Interactions,
  Events,
} from './fields';

import { useGroupData } from '../../../api/GroupDataContext';

export default function Contact({
  contact, editMode, setEditContact, scrollBarRef, setContact, page, callback,
}) {
  const [editing, setEditing] = useState(editMode);
  const { groupData } = useGroupData();
  const [id, setid] = useState(contact.id);
  const [urlString, setUrlString] = useState(contact.urlString);
  const [firstName, setFirstName] = useState(contact.firstName ?? '');
  const [lastName, setLastName] = useState(contact.lastName ?? '');
  const [phoneNumbers, setPhoneNumbers] = useState(contact.phones.length > 0 ? contact.phones : ['']);
  const [emails, setEmails] = useState(contact.emails.length > 0 ? contact.emails : ['']);
  const [groups, setGroups] = useState(contact.groups ?? []);
  const [birthdayDay, setBirthdayDay] = useState(contact.birthday?.day || '');
  const [birthdayMonth, setBirthdayMonth] = useState(contact.birthday?.month || '');
  const [birthdayYear, setBirthdayYear] = useState(contact.birthday?.year || '');
  const [birthdayReminder, setBirthdayReminder] = useState(true);
  const [frequencyGoal, setFrequencyGoal] = useState(contact.frequencyGoal ?? 0);
  const [communicationHistory, setCommunicationHistory] = useState(contact.communicationHistory);
  const [notes, setNotes] = useState(contact.notes ?? '');
  const [lastContacted, setLastContacted] = useState(contact.lastContacted);
  const [socialMedias, setSocialMedias] = useState(contact.socialMedias.length > 0 ? contact.socialMedias : ['']);
  const [colour, setColour] = useState(contact.colour ?? '');
  const [image, setImage] = useState(contact.image ?? '');
  const [formImage, setFormImage] = useState(null);

  const [confirmUpdate, setConfirmUpdate] = useState(false);
  const [cancelUpdate, setCancelUpdate] = useState(false);
  const { updateContactById, deleteContact } = useContactDataUpdate();

  const [redirect, setRedirect] = useState(null);

  if (callback) {
    callback();
  }

  const [boxContent, setBoxContent] = useState({
    Timeline:
    (
      <Events
        key={contact.id}
        contact={contact}
      />
    ),
    Interactions:
    (
      <Interactions
        key={contact.id}
        frequencyGoal={frequencyGoal}
        setFrequencyGoal={setFrequencyGoal}
        communicationHistory={communicationHistory}
        setCommunicationHistory={setCommunicationHistory}
        page={page}
        contact={contact}
      />
    ),
    Notes:
    (
      <Notes
        key={contact.id}
        notes={notes}
        setNotes={setNotes}
        contact={contact}
        editing
      />
    ),
  });

  useEffect(() => {
    setid(contact.id);
    setUrlString(contact.urlString);
    setEditing(editMode);
    setFirstName(contact.firstName ?? '');
    setLastName(contact.lastName ?? '');
    setPhoneNumbers(contact.phones.length > 0 ? contact.phones : ['']);
    setEmails(contact.emails.length > 0 ? contact.emails : ['']);
    setGroups(contact.groups ?? ['']);
    setBirthdayDay(contact.birthday?.day || '');
    setBirthdayMonth(contact.birthday?.month || '');
    setBirthdayYear(contact.birthday?.year || '');
    setFrequencyGoal(contact.frequencyGoal ?? '');
    setCommunicationHistory(contact.communicationHistory ?? []);
    setNotes(contact.notes ?? '');
    setLastContacted(contact.lastContacted);
    setSocialMedias(contact.socialMedias.length > 0 ? contact.socialMedias : ['']);
    setColour(contact.colour ?? '');
    setImage(contact.image ?? '');
    setFormImage(null);

    setConfirmUpdate(false);
    setCancelUpdate(false);

    setBoxContent({
      Timeline:
      (
        <Events
          key={contact.id}
          contact={contact}
        />
      ),
      Interactions:
      (
        <Interactions
          key={contact.id}
          frequencyGoal={frequencyGoal}
          setFrequencyGoal={setFrequencyGoal}
          communicationHistory={communicationHistory}
          setCommunicationHistory={setCommunicationHistory}
          page={page}
          contact={contact}
        />
      ),
      Notes:
        (
          <Notes
            key={contact.id}
            notes={notes}
            setNotes={setNotes}
            contact={contact}
            editing
          />
        ),
    });
  }, [contact, editMode]);

  useEffect(() => {
    setBoxContent({
      Timeline:
      (
        <Events
          key={contact.id}
          contact={contact}
        />
      ),
      Interactions:
      (
        <Interactions
          key={contact.id}
          frequencyGoal={frequencyGoal}
          setFrequencyGoal={setFrequencyGoal}
          communicationHistory={communicationHistory}
          setCommunicationHistory={setCommunicationHistory}
          page={page}
          contact={contact}
        />
      ),
      Notes:
        (
          <Notes
            key={contact.id}
            notes={notes}
            setNotes={setNotes}
            contact={contact}
            editing
          />
        ),
    });
  }, [notes, frequencyGoal, communicationHistory]);

  function getYearFromBirthday(day, month, age) {
    const currYear = new Date().getFullYear();
    const birthdayThisYear = new Date(currYear, month, day);
    if (new Date() - birthdayThisYear.setHours(0, 0, 0, 0) < 0) {
      return currYear - age - 1;
    }
    return currYear - age;
  }

  const handleUpdate = () => {
    const newContact = {
      id,
      urlString,
      firstName,
      lastName,
      emails,
      birthday: {
        day: birthdayDay,
        month: birthdayMonth,
        year: birthdayYear < 1000 && birthdayYear !== '' ? getYearFromBirthday(birthdayDay, birthdayMonth, birthdayYear) : birthdayYear,
      },
      phones: phoneNumbers,
      socialMedias,
      notes,
      communicationHistory,
      lastContacted,
      groups,
      frequencyGoal,
      image,
      colour,
    };

    if (setContact) {
      setContact(newContact);
    } else {
      setRedirect('/contact/' + contact.urlString);
    }

    // can't call two hooks at once, so we wait literally 1 ms
    // to update the contact to allow to page to redirect first
    setTimeout(() => {
      updateContactById(id, newContact, formImage);
    }, 1);
  };

  useEffect(() => {
    if (confirmUpdate) {
      if (setEditContact) {
        // setEditContact(false);
      }
      handleUpdate();
      setEditing(false);
    }

    if (cancelUpdate) {
      setEditing(false);
      if (setEditContact) {
        setEditContact(false);
      } else {
        setRedirect('/contact/' + contact.urlString);
      }
    }
  }, [confirmUpdate, cancelUpdate]);

  const handleDelete = () => {
    setRedirect('/');
    setTimeout(() => {
      deleteContact(id);
    }, 1);
  };

  if (redirect) {
    return (
      <Redirect push to={redirect} />
    );
  }
  return (
    <div className="cp-container">
      <div className="cp-header">
        {(!editing && page === 'contact page')
        && (
          <EditDelete
            editFunction={() => setRedirect('/contact/' + contact.urlString + '/edit/')}
            deleteFunction={() => handleDelete()}
          />
        )}
        <ProfilePicture
          fname={firstName + lastName !== '' ? firstName : 'First name'}
          lname={firstName + lastName !== '' ? lastName : 'Last name'}
          width={page === 'contact page' ? '150px' : '120px'}
          height={page === 'contact page' ? '150px' : '120px'}
          initialCount={2}
          contact
          edit={editing}
          editFuncs={{ setColour, setImage, setFormImage }}
          colour={colour}
          image={image}
        />
        <h1>
          {[firstName, lastName].filter(Boolean).join(' ')}
        </h1>
      </div>
      <div className="cp-main">
        <form>
          {editing
            ? (
              <Name
                firstName={firstName}
                lastName={lastName}
                setFirstName={setFirstName}
                setLastName={setLastName}
                editing
              />
            )
            : (
              <SocialMedias
                socialMedias={socialMedias}
                setSocialMedias={setSocialMedias}
                editing={editing}
              />
            )}
          <PhoneNumbers
            phoneNumbers={phoneNumbers}
            setPhoneNumbers={setPhoneNumbers}
            editing={editing}
          />
          <Emails
            emails={emails}
            setEmails={setEmails}
            editing={editing}
          />
          {editing
          && (
            <SocialMedias
              socialMedias={socialMedias}
              setSocialMedias={setSocialMedias}
              editing={editing}
            />
          )}
          <Birthday
            birthdayDay={birthdayDay}
            birthdayMonth={birthdayMonth}
            birthdayYear={birthdayYear}
            birthdayReminder={birthdayReminder}
            setBirthdayDay={setBirthdayDay}
            setBirthdayMonth={setBirthdayMonth}
            setBirthdayYear={setBirthdayYear}
            setBirthdayReminder={setBirthdayReminder}
            editing={editing}
          />
          <Tags
            tags={groups}
            values={groupData}
            onChange={(newGroups) => setGroups(newGroups)}
            element={(groupID) => {
              const group = groupData.find((g) => g.id === groupID);
              return (
                <>
                  <ProfilePicture
                    name={group.name}
                    colour={group.colour}
                    image={group.image}
                    width="28px"
                    height="28px"
                  />
                  {group.name}
                </>
              );
            }}
            text="Add Group"
            searchKeys={['name']}
            editing={editing}
            linkStub="/group/"
          />
        </form>
      </div>

      {!editing
        ? (
          <div
            className="cp-secondary"
            role="none"
            onKeyUp={() => {}}
            onClick={() => scrollBarRef !== undefined
              && (scrollBarRef.current.getScrollTop() < window.innerHeight
              / 2 && scrollBarRef.current.scrollTop(window.innerHeight / 2))}
          >
            <BoxToggle
              key={id}
              editing={editing}
              boxContent={boxContent}
            />
          </div>
        )
        : (
          <Buttons
            confirm={setConfirmUpdate}
            cancel={setCancelUpdate}
          />
        )}
    </div>
  );
}
