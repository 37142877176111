import { useViewport } from '../../../api/ViewportContext';
import NavBarDesktop from './NavBarDesktop';
import NavBarMobile from './NavBarMobile';

export default function NavBarContainer() {
  const { width, height } = useViewport();
  const widthBreakpoint = 600;
  const heightBreakpoint = 600;

  return (width > widthBreakpoint)
          && (height > heightBreakpoint) ? (<NavBarDesktop />) : (<NavBarMobile />);
}
