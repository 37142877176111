import './ContactsHeader.scss';
import { Redirect } from 'react-router';
import { useState } from 'react';
import { useGroupDataUpdate } from '../../../api/GroupDataContext';
import { useContactData } from '../../../api/ContactDataContext';
import { ProfilePicture } from '../../common/ProfilePicture';
import { EditDelete } from '../../common/buttons/EditDelete';

import iconConfirm from '../../../images/icon-confirm.png';
import iconCancel from '../../../images/icon-cancel.png';

export default function ContactsHeader({ group }) {
  const [groupName, setGroupName] = useState(group ? group.name : '');
  const [colour, setColour] = useState(group?.colour ?? '');
  const [image, setImage] = useState(group?.image ?? '');
  const [editing, setEditing] = useState(false);
  const { deleteGroup, updateGroupById } = useGroupDataUpdate();
  const { contactData } = useContactData();
  const [redirect, setRedirect] = useState(null);

  if (redirect) {
    return (
      <Redirect push to={redirect} />
    );
  }

  const handleDelete = (option) => {
    setRedirect('/');
    setTimeout(() => {
      deleteGroup(group.id, option);
    }, 1);
  };

  const handleUpdate = () => {
    const newGroup = group;
    newGroup.name = groupName;
    newGroup.colour = colour;
    newGroup.image = image;
    updateGroupById(group.id, newGroup);
    setEditing(false);
  };

  const contactCount = (
    group ? contactData.filter((c) => c.groups.length === 1 && c.groups[0] === group.id).length : 0
  );

  return (
    <div className="group-header">
      {group
      && (
        !editing
          ? (
            <EditDelete
              editFunction={() => setEditing(true)}
              deleteFunction={(option) => handleDelete(option)}
              deleteOption={contactCount === 0 ? null : ('Delete ' + contactCount + ' contact' + (contactCount > 1 ? 's' : '') + ' only in this group')}
            />
          )
          : (
            <div className="group-header-edit-buttons">
              <div
                className="group-header-edit-confirm"
                role="button"
                tabIndex="0"
                onClick={() => handleUpdate()}
                onKeyDown={(e) => e.key === 'Enter' && handleUpdate()}
              >
                <img src={iconConfirm} alt="" />
              </div>
              <div
                className="group-header-edit-cancel"
                role="button"
                tabIndex="0"
                onClick={() => setEditing(false)}
                onKeyDown={(e) => e.key === 'Enter' && setEditing(false)}
              >
                <img src={iconCancel} alt="" />
              </div>
            </div>
          ))}
      {group
        ? (
          <>
            <ProfilePicture
              name={groupName || 'New Group'}
              width="80px"
              height="80px"
              edit={editing}
              editFuncs={{ setColour, setImage, setFormImage: () => {} }}
              colour={colour}
              image={image}
            />
            <input
              type="text"
              readOnly={!editing}
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />
          </>
        )
        : (
          <h1>Contacts</h1>
        )}
    </div>
  );
}
