import axios from 'axios';
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';

import { BASE_URL, reauthenticate } from '.';

const userDataContext = createContext({});
const userDataUpdateContext = createContext({});

export default function UserDataContext({ children }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    getUser();
  }, []);

  function getUser() {
    axios
      .get(`${BASE_URL}/user`)
      .then((res) => {
        setUser(res.data);
      })
      .catch(() => {
        reauthenticate().then(() => getUser());
      });
  }

  return (
    <userDataUpdateContext.Provider value={{ }}>
      <userDataContext.Provider value={{ user }}>
        {children}
      </userDataContext.Provider>
    </userDataUpdateContext.Provider>
  );
}

export function useUserData() {
  const { user } = useContext(userDataContext);
  return { user };
}
