import React, {
  memo, useRef, useState, useEffect,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useViewport } from '../../../api/ViewportContext';
import { useEventData } from '../../../api/EventDataContext';
import { groupBy } from '../../common/functions';
import EventCard from './EventCard';
import './Timeline.scss';
import TimelineHeader from './TimelineHeader';

function Timeline() {
  const [typeFilter, setTypeFilter] = useState(null);
  const [eventsLength, setEventsLength] = useState(10);
  const [futureEvents, setFutureEvents] = useState([]);
  const { eventData } = useEventData();
  const { isMobile } = useViewport();
  const timezoneOffset = new Date().getTimezoneOffset();
  const currDate = parseInt((new Date().getTime()
    - (timezoneOffset * 60 * 1000)) / (60 * 60 * 1000 * 24), 10);
  const [groupedEvents, setGroupedEvents] = useState(
    groupBy(eventData, (e) => parseInt((new Date(e.start).getTime()
    - (timezoneOffset * 60 * 1000)) / (60 * 60 * 1000 * 24), 10)),
  );
  const eventsRef = useRef(null);
  const [timelineHeight, setTimelineHeight] = useState('400px');

  useEffect(() => {
    setGroupedEvents(
      groupBy(eventData.filter((e) => (typeFilter ? e.type === typeFilter : true)),
        (e) => parseInt((new Date(e.start).getTime()
        - (timezoneOffset * 60 * 1000)) / (60 * 60 * 1000 * 24), 10)),
    );
  }, [typeFilter, eventData]);

  useEffect(() => {
    setFutureEvents(Array.from(groupedEvents)
      .filter((e) => e[0] - currDate >= 0)
      .sort((e1, e2) => e1[0] - e2[0])
      .slice(0, eventsLength));
  }, []);

  useEffect(() => {
    setFutureEvents(Array.from(groupedEvents)
      .filter((e) => e[0] - currDate >= 0)
      .sort((e1, e2) => e1[0] - e2[0])
      .slice(0, eventsLength));
  }, [eventsLength, groupedEvents]);

  useEffect(() => {
    const { top } = eventsRef.current.getBoundingClientRect();
    setTimelineHeight(`calc(100vh - ${top}px ${isMobile ? '- 50px' : ''})`);
  }, [eventsRef]);

  return (
    <>
      <div className="timeline-container" id="timeline-scroll">
        <TimelineHeader
          setFilter={(type) => setTypeFilter(type)}
          currType={typeFilter}
          events={eventData}
        />
        <div className="timeline">
          <div className="timeline-events" ref={eventsRef}>
            {Array.from(groupedEvents).length === 0
            && (
              <p>No recorded events</p>
            )}
            <div id="scroll" style={{ height: timelineHeight, overflowY: 'scroll' }}>
              <InfiniteScroll
                dataLength={eventsLength}
                loader={<h4>Loading...</h4>}
                next={() => setEventsLength(eventsLength + 5)}
                hasMore
                scrollableTarget="scroll"
              >
                {futureEvents.map(([days, events]) => {
                  const eventDate = new Date(days * 60 * 60 * 1000 * 24);
                  const date = eventDate.toLocaleDateString(navigator.language, { month: 'short', day: 'numeric' });
                  const year = eventDate.toLocaleDateString(navigator.language, { year: 'numeric' });
                  return (
                    <div key={days}>
                      <div className="timeline-date-text">
                        <h3>{date + (new Date().getFullYear() === parseInt(year, 10) ? '' : ` ${year}`)}</h3>
                      </div>
                      <div className="timeline-date-events">
                        {events.sort((e1, e2) => new Date(e1.start)
                          - new Date(e2.start)).map((event, i) => (
                            <EventCard
                              key={i}
                              event={event}
                            />
                        ))}
                      </div>
                    </div>
                  );
                })}
              </InfiniteScroll>

            </div>
          </div>
        </div>
        <div className="timeline-options" />
      </div>
    </>
  );
}

export default memo(Timeline);
