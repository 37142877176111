import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { v4 as uuidv4 } from 'uuid';
import { useContactData } from './api/ContactDataContext';
import { useGroupData } from './api/GroupDataContext';
import './styles/customScroll.css';
import { NotFound, InteractionBoxes } from './components/overlays';

// IMPORT COMPONENTS //

// pages
import { Contact } from './components/pages/Contact';
import { Contacts } from './components/pages/Contacts';
import { Dashboard } from './components/pages/Dashboard';
import { Timeline } from './components/pages/Timeline';
import { Event } from './components/pages/Event';
import { SearchResults } from './components/pages/SearchResults';

// layout
import { NavBar } from './components/layout/NavBar';
import { Header } from './components/layout/Header';
import { useEventData } from './api/EventDataContext';
import { useSearchQuery } from './api/SearchQueryContext';
import { KeepInTouch } from './components/pages/KeepInTouch';
import { logout } from './api';
import { useUserData } from './api/UserDataContext';

// HELPER FUNCTIONS //

/**
 * Adds css file to head of html page
 * Normally this would be imported, but we don't want React to combine these
 * with other CSS files as we want to be able to change the file path during runtime
 * @param {string} id         id of link element so it can be referenced
 * @param {string} stylePath  path containing css file
 * @returns null
 */
function AppendStyleSheet(id, stylePath) {
  if (document.getElementById(id)) {
    return;
  }
  const { head } = document;
  const link = document.createElement('link');
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.href = stylePath;
  link.id = id;
  head.appendChild(link);
}

// MAIN //

export default function App() {
  const darkTheme = '/styles/themes/dark.css';
  const lightTheme = '/styles/themes/light.css';
  const { contactData } = useContactData();
  const { eventData } = useEventData();
  const { groupData } = useGroupData();
  const { searchQuery } = useSearchQuery();
  const { user } = useUserData();

  let loadTheme = lightTheme;

  if (localStorage.getItem('theme') === 'dark') {
    loadTheme = darkTheme;
  }

  AppendStyleSheet('theme_css', loadTheme);

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: 'rgba(100,100,100, 0.8)',
      cursor: 'pointer',
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  if (!localStorage.getItem('refreshToken')) { logout(); }

  if (!contactData || !eventData || !groupData || !user) {
    return (<></>);
  }
  if (document.getElementById('preloader')) {
    document.getElementById('preloader').classList.add('hidden');
  }

  return (
    <Router>
      <div className="container">
        <Header />
        <NavBar />
        <InteractionBoxes />
        <div className="content-container">
          <Scrollbars
            renderThumbVertical={renderThumb}
            renderThumbHorizontal={renderThumb}
            autoHide
          >
            <div className="inner-content-container">
              <Switch>
                <Route exact path="/">
                  <Dashboard />
                </Route>
                <Route path="/timeline">
                  <Timeline />
                </Route>
                <Route path="/groups">
                  <Contacts />
                </Route>
                <Route path="/contacts" exact>
                  <Contacts />
                </Route>
                {contactData && contactData.map((contact) => (
                  <Route
                    exact
                    key={uuidv4()}
                    path={'/contact/' + contact.urlString}
                  >
                    <Contact contact={contact} editMode={false} page="contact page" />
                  </Route>
                ))}
                {contactData && contactData.map((contact) => (
                  <Route
                    exact
                    key={uuidv4()}
                    path={'/contact/' + contact.urlString + '/edit'}
                  >
                    <Contact contact={contact} editMode page="contact page edit" />
                  </Route>
                ))}
                {groupData && groupData.map((group, i) => (
                  <Route
                    key={i}
                    path={'/group/' + group.urlString}
                    exact
                  >
                    <Contacts group={group} />
                  </Route>
                ))}
                {eventData && eventData.map((event, i) => (
                  <Route
                    key={i}
                    path={'/event/' + event.urlString}
                    exact
                  >
                    <Event event={event} />
                  </Route>
                ))}
                <Route
                  path="/search/"
                >
                  <SearchResults query={searchQuery || window.location.href.split('/').at(-1)} />
                </Route>
                <Route
                  path="/keep-in-touch/"
                  exact
                >
                  <KeepInTouch />
                </Route>
                <Route>
                  <NotFound />
                </Route>
              </Switch>
            </div>
          </Scrollbars>
        </div>
      </div>
    </Router>
  );
}
