import './NotFound.scss';

export default function NotFound() {
  return (
    <div className="not-found-overlay">
      <h2>Woah there cowboy</h2>
      <div className="not-found-link">
        <h3>This page doesn&apos;t exist</h3>
      </div>
    </div>
  );
}
