import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './SlidingButton.scss';

// MAIN COMPONENT //

/**
 *
 * @param   {*} props
 * @returns {}
 */
function SlidingButton(props) {
  const { position, onChange } = props;
  return (
    <div
      className={'sliding-button-container' + (!position ? ' sliding-button-off' : '')}
      onClick={() => onChange()}
      onKeyUp={() => onChange()}
      role="button"
      tabIndex="0"
    >
      <div className={`sliding-button-notch sliding-button-notch-${position ? 'right' : 'left'}`} />
    </div>
  );
}

// PROP TYPES //

SlidingButton.propTypes = {
  position: PropTypes.bool,
};

SlidingButton.defaultProps = {
  position: true,
};

export default memo(SlidingButton);
