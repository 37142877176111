import { logout } from '../../../../api';
import { ProfilePicture } from '../../../common/ProfilePicture';
import './Settings.scss';

export default function Settings({ fname, lname }) {
  return (
    <div className="dropdown-settings">
      <ProfilePicture
        fname={fname}
        lname={lname}
        width="80px"
        height="80px"
        initialCount={2}
        contact
      />
      <h2>
        {[fname, lname].filter(Boolean).join(' ')}
      </h2>
      <nav className="dropdown-settings-nav">
        <a href="https://www.phonebookplus.com/account">
          <div className="dropdown-settings-nav-item">
            <span>My account</span>
          </div>
        </a>
        <div
          className="dropdown-settings-nav-item"
          role="button"
          tabIndex="0"
          onClick={() => logout()}
          onKeyUp={(e) => e.key === 'Enter' && logout()}
        >
          <span>Log out</span>
        </div>
      </nav>
    </div>
  );
}
