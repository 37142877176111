import { useState, useRef } from 'react';
import { COLOURS } from '../functions';
import './EditIcon.scss';

import iconCamera from '../../../images/icon-camera.png';

export default function EditIcon({
  editFuncs, profilepic, closeUI, currColour, currImage,
}) {
  const [colour, setColour] = useState(currColour);
  const [image, setImage] = useState(currImage);
  const [formImage, setFormImage] = useState(null);
  const fileUploadRef = useRef(null);

  const handleSubmit = () => {
    editFuncs.setColour(colour);
    editFuncs.setImage(image);
    editFuncs.setFormImage(formImage);
    closeUI();
  };

  const changeColour = (newColour) => {
    setColour(newColour);
    if (image) {
      setImage('');
    }
  };

  const uploadImage = (file) => {
    const newImage = new FormData();
    newImage.append('image', file);
    setFormImage(newImage);
    setImage(URL.createObjectURL(file));
  };

  return (
    <div className="edit-icon-container">
      <div className="edit-icon-image">
        <input
          type="file"
          ref={fileUploadRef}
          style={{ display: 'none' }}
          accept="image/png, image/jpeg"
          onChange={(e) => uploadImage(e.target.files[0])}
        />
        {profilepic(colour, image)}
        <div
          className="edit-icon-upload-button"
          role="button"
          tabIndex="0"
          onClick={() => fileUploadRef.current.click()}
          onKeyUp={(e) => e.key === 'Enter' && fileUploadRef.current.click()}
        >
          <img src={iconCamera} alt="" />
          <span>Upload image</span>
        </div>
      </div>
      <div className="edit-icon-colours">
        {COLOURS.map((colourHex, i) => (
          <div
            key={i}
            className="edit-icon-colour"
            role="button"
            aria-label="colour"
            tabIndex="0"
            onClick={() => changeColour(colourHex)}
            onKeyUp={(e) => e.key === 'Enter' && changeColour(colourHex)}
            style={
              {
                backgroundColor: colourHex,
              }
            }
          />
        ))}
      </div>
      <div
        className="edit-icon-save"
        role="button"
        tabIndex="0"
        onClick={() => handleSubmit()}
        onKeyUp={(e) => e.key === 'Enter' && handleSubmit()}
      >
        Save
      </div>
    </div>
  );
}
