/* eslint-disable no-param-reassign */
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';

import axios from 'axios';
import { useContactDataUpdate } from './ContactDataContext';
import { BASE_URL, reauthenticate } from '.';

const groupDataContext = createContext([]);
const groupDataUpdateContext = createContext([]);

export default function GroupDataProvider({ children }) {
  const [groupData, setGroupData] = useState(null);
  const { removeGroupFromContacts } = useContactDataUpdate();

  useEffect(() => {
    getGroups();
  }, []);

  function getGroups() {
    axios
      .get(`${BASE_URL}/groups`)
      .then((res) => {
        setGroupData(res.data);
      })
      .catch(() => {
        reauthenticate().then(() => getGroups());
      });
  }

  function addGroup(groupObject) {
    return (
      axios
        .post(`${BASE_URL}/groups`, { ...groupObject })
        .then((res) => {
          groupObject.id = res.data;
          const updatedGroups = groupData.concat(groupObject);
          setGroupData(updatedGroups);
          return res.data;
        })
        .catch(() => {
          reauthenticate().then(() => addGroup(groupObject));
        })
    );
  }

  function updateGroupById(id, groupObject) {
    const updatedGroups = groupData.map((g) => (g.id === id ? groupObject : g));
    setGroupData(updatedGroups);
    axios
      .put(`${BASE_URL}/groups/${id}`, { groupID: id, fields: { ...groupObject } })
      .catch(() => {
        reauthenticate().then(() => updateGroupById(id, groupObject));
      });
  }

  function deleteGroup(id, deleteOption) {
    const updatedGroups = groupData.filter((g) => g.id !== id);
    setGroupData(updatedGroups);
    removeGroupFromContacts(id, deleteOption);
    axios
      .post(`${BASE_URL}/deleteGroup`, { groupID: id, deleteContacts: deleteOption })
      .catch(() => {
        reauthenticate().then(() => deleteGroup(id, deleteOption));
      });
  }

  return (
    <groupDataUpdateContext.Provider value={{ addGroup, updateGroupById, deleteGroup }}>
      <groupDataContext.Provider value={{ groupData }}>
        {children}
      </groupDataContext.Provider>
    </groupDataUpdateContext.Provider>
  );
}

export function useGroupData() {
  const { groupData } = useContext(groupDataContext);
  return { groupData };
}

export function useGroupDataUpdate() {
  const { addGroup, updateGroupById, deleteGroup } = useContext(groupDataUpdateContext);
  return { addGroup, updateGroupById, deleteGroup };
}
