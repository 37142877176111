import {
  React, useState, useEffect, memo,
} from 'react';
import { useViewport } from '../../../api/ViewportContext';
import { InteractionBox } from '../../overlays';
import {
  AddContact,
  AddEvent,
  AddGroup,
  AddInteraction,
} from '../../quickoptions';

import addContactIcon from '../../../images/add/add-contact.png';
import addGroupIcon from '../../../images/add/add-group.png';
import addEventIcon from '../../../images/add/add-event.png';
import addInteractionIcon from '../../../images/add/add-interaction.png';

import './AddItemDropdown.scss';

function AddItemDropdown({ closeUI, dropdownRef, quickOpen }) {
  const [openBox, setOpenBox] = useState(null);
  const { isMobile } = useViewport();

  useEffect(() => {
    if (quickOpen) {
      switch (quickOpen) {
        case 'c':
          setOpenBox(<AddContact closeUI={closeUI} />);
          break;
        case 'g':
          setOpenBox(<AddGroup closeUI={closeUI} />);
          break;
        case 'e':
          setOpenBox(<AddEvent closeUI={closeUI} />);
          break;
        case 'i':
          setOpenBox(<AddInteraction closeUI={closeUI} />);
          break;
        default:
      }
    }
  }, [quickOpen]);

  if (openBox !== null) {
    if (!isMobile) {
      dropdownRef.current.style.boxShadow = 'none';
      dropdownRef.current.style.border = 'none';
      return (
        <InteractionBox closeUI={closeUI} width="600px" height="700px">
          {openBox}
        </InteractionBox>
      );
    }
    return (
      <>
        {openBox}
      </>
    );
  }

  return (
    <div className="aid-grid">
      <div
        className="aid-grid-item"
        role="button"
        tabIndex="0"
        onKeyUp={(e) => e.key === 'Enter' && setOpenBox(<AddContact closeUI={closeUI} />)}
        onClick={(e) => {
          setOpenBox(<AddContact closeUI={closeUI} />);
          e.stopPropagation();
        }}
      >
        <img src={addContactIcon} alt="" />
        <span>Contact</span>
      </div>
      <div
        className="aid-grid-item"
        role="button"
        tabIndex="0"
        onKeyUp={(e) => e.key === 'Enter' && setOpenBox(<AddGroup closeUI={closeUI} />)}
        onClick={(e) => {
          setOpenBox(<AddGroup closeUI={closeUI} />);
          e.stopPropagation();
        }}
      >
        <img src={addGroupIcon} alt="" />
        <span>Group</span>
      </div>
      <div
        className="aid-grid-item"
        role="button"
        tabIndex="0"
        onKeyUp={(e) => e.key === 'Enter' && setOpenBox(<AddEvent closeUI={closeUI} />)}
        onClick={(e) => {
          setOpenBox(<AddEvent closeUI={closeUI} />);
          e.stopPropagation();
        }}
      >
        <img src={addEventIcon} alt="" />
        <span>Event</span>
      </div>
      <div
        className="aid-grid-item"
        role="button"
        tabIndex="0"
        onKeyUp={(e) => e.key === 'Enter' && setOpenBox(<AddInteraction closeUI={closeUI} />)}
        onClick={(e) => {
          setOpenBox(<AddInteraction closeUI={closeUI} />);
          e.stopPropagation();
        }}
      >
        <img src={addInteractionIcon} alt="" />
        <span>Interaction</span>
      </div>
    </div>
  );
}

export default memo(AddItemDropdown);
