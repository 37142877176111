import BirthdayWidget from './BirthdayWidget';
import TimelineWidget from './TimelineWidget';
import ContactWidget from './ContactWidget';
import PieChartWidget from './PieChartWidget';
import './Widget.scss';

function Widget({ type, options }) {
  let widget = null;
  switch (type) {
    case 'contacts':
      widget = <ContactWidget options={options} />;
      break;
    case 'timeline':
      widget = <TimelineWidget options={options} />;
      break;
    case 'birthday':
      widget = <BirthdayWidget options={options} />;
      break;
    case 'pie':
      widget = <PieChartWidget options={options} />;
      break;
    default:
      widget = <div>Something went wrong</div>;
  }

  return (
    <div
      className="widget-container"
    >
      {widget}
    </div>
  );
}

export default Widget;
