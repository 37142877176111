import './Birthday.scss';
import iconBirthday from '../../../../images/icon-birthday.png';

function getNextBirthday(day, month, birthdayYear) {
  const dateTime = new Date();
  const dayLength = 24 * 60 * 60 * 1000;
  let year = dateTime.getFullYear();
  let bdayMilliseconds = new Date(year, month - 1, day);
  let dateDifference = parseInt((bdayMilliseconds - dateTime.setHours(0, 0, 0, 0)) / dayLength, 10);
  let nextAge = (birthdayYear ? year - birthdayYear : -1);
  let birthdayMessage = '';

  if (!bdayMilliseconds) {
    return (<></>);
  }

  if (dateDifference < -1) {
    year += 1;
    bdayMilliseconds = new Date(year, month - 1, day);
    dateDifference = parseInt((bdayMilliseconds - dateTime.setHours(0, 0, 0, 0)) / dayLength, 10);
    nextAge += 1;
  }

  if (dateDifference < 0) {
    birthdayMessage = (birthdayYear ? 'Turned ' + nextAge + ' yesterday.' : 'Their birthday was yesterday!');
  } else if (dateDifference < 1) {
    birthdayMessage = (birthdayYear ? 'Turned ' + nextAge + ' today!' : 'Their birthday is today!');
  } else if (dateDifference < 2) {
    birthdayMessage = (birthdayYear ? 'Turning ' + nextAge + ' tomorrow!' : 'Their birthday is tomorrow.');
  } else {
    birthdayMessage = (birthdayYear
      ? 'Turning ' + nextAge + ' in ' + dateDifference + ' days.'
      : 'Their birthday is in ' + dateDifference + ' days.');
  }
  return birthdayMessage;
}

export default function Birthday(props) {
  const {
    birthdayDay,
    birthdayMonth,
    birthdayYear,
    setBirthdayDay,
    setBirthdayMonth,
    setBirthdayYear,
    editing,
  } = props;

  if (birthdayYear?.length + birthdayMonth?.length + birthdayDay?.length === 0 && !editing) {
    return <></>;
  }

  if (!editing) {
    return (
      <div className="cp-birthday-flex cp-box">
        <img src={iconBirthday} alt="birthday cake" />
        <div>
          <h4 className="cp-birthday-date">
            {[birthdayDay, birthdayMonth, birthdayYear].filter(Boolean).join('/')}
          </h4>
          <h5 className="cp-birthday-text">
            {getNextBirthday(birthdayDay, birthdayMonth, birthdayYear)}
          </h5>
        </div>

      </div>
    );
  }

  function removeLetters(text) {
    return text.replace(/[^\d]/g, '');
  }

  return (
    <div className="cp-birthday">
      <h3 className="cp-field-label">Birthday</h3>
      <input
        readOnly={!editing}
        type="text"
        name="birthdayDay"
        value={birthdayDay}
        placeholder="Day*"
        maxLength={2}
        onChange={(e) => setBirthdayDay(removeLetters(e.target.value))}
        style={{ width: '25%', borderTopRightRadius: '0', borderBottomRightRadius: '0' }}
      />
      <input
        type="text"
        name="birthdayMonth"
        readOnly={!editing}
        value={birthdayMonth}
        placeholder="Month*"
        maxLength={2}
        onChange={(e) => setBirthdayMonth(removeLetters(e.target.value))}
        style={{ width: '25%', borderRadius: '0' }}
      />
      <input
        type="text"
        name="birthdayAge"
        readOnly={!editing}
        value={birthdayYear}
        placeholder="Year or Age (opt)"
        maxLength={4}
        onChange={(e) => setBirthdayYear(removeLetters(e.target.value))}
        style={{ width: '50%', borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}
      />
    </div>
  );
}
