import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useContactData } from '../../../api/ContactDataContext';
import { ProfilePicture } from '../../common/ProfilePicture';

function sortByUpcomingBirthday(contacts) {
  const currYear = new Date().getFullYear();
  //
  return (
    contacts
      .filter((c) => (
        c.birthday?.month && c.birthday?.day
      ))
      .map((c) => {
        const year = new Date() - new Date(currYear, c.birthday?.month - 1, c.birthday?.day) > 0
          ? currYear + 1 : currYear;
        return {
          ...c,
          birthdayDate: (
            new Date(year, c.birthday?.month - 1, c.birthday?.day)).toISOString(),
        };
      }).sort((c1, c2) => new Date(c1.birthdayDate) - new Date(c2.birthdayDate))
  );
}

function getContactsInGroup(contacts, groupID) {
  return contacts.filter((contact) => contact.groups.includes(groupID));
}

function BirthdayWidget({ options }) {
  const [redirect, setRedirect] = useState(null);
  const { contactData } = useContactData();
  let topContacts = contactData;

  if (options && options.group) {
    topContacts = getContactsInGroup(topContacts, options.group);
  }

  // prints contacts in order from last contacted, you can use this to make the widget
  topContacts = sortByUpcomingBirthday(topContacts);
  if (redirect) {
    return (
      <Redirect push to={redirect} />
    );
  }

  return (
    <>
      <h3 className="widget-title">Upcoming Birthdays</h3>
      {topContacts.slice(0, 4).map((contact) => (
        <div
          className="widget-contact-card"
          key={contact.id}
          role="button"
          tabIndex="0"
          onClick={() => setRedirect('/contact/' + contact.urlString)}
          onKeyUp={(e) => e.key === 'Enter' && setRedirect('/contact/' + contact.urlString)}
        >
          <ProfilePicture
            fname={contact.firstName}
            lname={contact.lastName}
            initialCount={2}
            width="35px"
            height="35px"
            colour={contact.colour}
            image={contact.image}
            contact
          />
          <p style={{ marginRight: 'auto', fontWeight: 500 }}>
            {[contact.firstName, contact.lastName].filter(Boolean).join(' ')}
          </p>
          <p className="widget-date">
            {new Date(contact.birthdayDate).toLocaleDateString(window.navigator.language, { month: 'short', day: 'numeric' })}
          </p>
        </div>
      ))}
    </>
  );
}

export default BirthdayWidget;
