import { useEffect, useState, useRef } from 'react';
import { useContactDataUpdate } from '../../../../api/ContactDataContext';
import './Notes.scss';
import iconConfirm from '../../../../images/icon-confirm.png';
import iconCancel from '../../../../images/icon-cancel.png';

export default function Notes({
  notes, editing, contact, setNotes,
}) {
  const [newNotes, setNewNotes] = useState(notes);
  const [save, setSave] = useState(false);
  const notesRef = useRef();
  const { updateContactById } = useContactDataUpdate();

  const autoGrow = (e) => {
    e.style.height = '5px';
    e.style.height = (e.scrollHeight) + 'px';
  };

  useEffect(() => {
    if (notesRef.current !== null) {
      autoGrow(notesRef.current);
    }
  }, [notesRef.current]);

  useEffect(() => {
    setNotes(newNotes);
  }, [newNotes]);

  const handleUpdate = () => {
    const newContactData = contact;
    newContactData.notes = newNotes;
    updateContactById(contact.id, contact);
    setSave(false);
  };

  return (
    <>
      <textarea
        className="cp-notes"
        placeholder="Notes"
        readOnly={!editing}
        maxLength="1000"
        value={newNotes}
        onChange={(e) => {
          setNewNotes(e.target.value);
          autoGrow(e.target);
          setSave(true);
        }}
        ref={notesRef}
      />
      {(notes !== newNotes && save)
      && (
        <div className="cp-notes-save-cancel">
          <div
            className="cp-notes-save"
            role="button"
            tabIndex="0"
            onClick={() => handleUpdate()}
            onKeyUp={(e) => e.key === 'Enter' && handleUpdate()}
          >
            <img src={iconConfirm} alt="" />
          </div>
          <div
            className="cp-notes-cancel"
            role="button"
            tabIndex="0"
            onClick={() => setNewNotes(notes)}
            onKeyUp={(e) => e.key === 'Enter' && setNewNotes(notes)}
          >
            <img src={iconCancel} alt="" />
          </div>
        </div>
      )}
    </>
  );
}
