import { useEffect, useState } from 'react';
import { useEventDataUpdate } from '../../api/EventDataContext';
import RainbowDateTimePicker from '../common/RainbowDateTimePicker';
import { useContactData } from '../../api/ContactDataContext';

import { ProfilePicture } from '../common/ProfilePicture';
import { Tags } from '../common/Tags';
import './AddEvent.scss';

export default function AddEvent({ closeUI }) {
  const currDateTime = new Date();
  const [eventName, setEventName] = useState('');
  const [startTime, setStartTime] = useState(new Date(currDateTime.getTime()
    + 60 * 60 * 1000 * 24));
  const [endTime, setEndTime] = useState(new Date(currDateTime.getTime() + 60 * 60 * 1000 * 25));
  const [type, setType] = useState('');
  const [notes, setNotes] = useState('');
  const [contacts, setContacts] = useState([]);
  const { contactData } = useContactData();

  const { addEvent } = useEventDataUpdate();

  useEffect(() => {}, []);

  const handleSubmit = () => {
    if (eventName === '') {
      return;
    }

    const newEvent = {
      name: eventName,
      start: startTime,
      end: endTime,
      type,
      notes,
      contacts: [],
    };
    addEvent(newEvent);
    closeUI();
  };

  return (
    <div className="ae-container">
      <div className="ae-name">
        <input
          type="text"
          placeholder="Event Name"
          value={eventName}
          onChange={(e) => setEventName(e.target.value)}
        />
      </div>
      <div className="ae-start ae-date">
        <span>Start of event:</span>
        <RainbowDateTimePicker
          className="ae-datepicker"
          value={startTime}
          onChange={setStartTime}
        />
      </div>
      <div className="ae-end ae-date">
        <span>End of event:</span>
        <RainbowDateTimePicker className="ae-datepicker" value={endTime} onChange={setEndTime} />
      </div>
      <div className="ae-desc">
        <textarea
          value={notes}
          placeholder="Notes"
          onChange={(e) => setNotes(e.target.value)}
        />
      </div>
      <Tags
        tags={contacts}
        values={contactData}
        onChange={(newContacts) => setContacts(newContacts)}
        element={(id) => {
          const contact = contactData.find((c) => c.id === id);
          return (
            <>
              <ProfilePicture
                fname={contact.firstName}
                lname={contact.lastName}
                colour={contact.colour}
                image={contact.image}
                width="28px"
                height="28px"
                initialCount={2}
                contact
              />
              {[contact.firstName, contact.lastName].filter(Boolean).join(' ')}
            </>
          );
        }}
        text="Add Contacts"
        searchKeys={['firstName', 'lastName']}
        editing
      />
      <input
        className="ae-tag"
        type="text"
        value={type}
        onChange={(e) => setType(e.target.value)}
        maxLength={40}
        placeholder="Tag"
      />
      <div
        className="ae-submit"
        role="button"
        tabIndex="0"
        onClick={() => handleSubmit()}
        onKeyUp={(e) => e.key === 'Enter' && handleSubmit()}
      >
        <span>Create Event</span>
      </div>
    </div>
  );
}
