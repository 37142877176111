import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useContactData } from '../../../api/ContactDataContext';
import { ProfilePicture } from '../../common/ProfilePicture';
import './Widget.scss';

function sortByLastContacted(contacts) {
  return (
    contacts
      .filter((c) => c.lastContacted)
      .sort((c1, c2) => new Date(c2.lastContacted) - new Date(c1.lastContacted))
  );
}

function getContactsInGroup(contacts, groupID) {
  return contacts.filter((contact) => contact.groups.includes(groupID));
}

function ContactWidget({ options }) {
  const [redirect, setRedirect] = useState(null);
  const { contactData } = useContactData();
  let topContacts = contactData;

  if (options && options.group) {
    topContacts = getContactsInGroup(topContacts, options.group);
  }

  // prints contacts in order from last contacted, you can use this to make the widget
  topContacts = sortByLastContacted(topContacts);
  if (redirect) {
    return (
      <Redirect push to={redirect} />
    );
  }

  return (
    <>
      <h3 className="widget-title">Last Contacted</h3>
      {topContacts.slice(0, 4).map((contact) => (
        <div
          className="widget-contact-card"
          key={contact.id}
          role="button"
          tabIndex="0"
          onClick={() => setRedirect('/contact/' + contact.urlString)}
          onKeyUp={(e) => e.key === 'Enter' && setRedirect('/contact/' + contact.urlString)}
        >
          <ProfilePicture
            fname={contact.firstName}
            lname={contact.lastName}
            initialCount={2}
            width="35px"
            height="35px"
            colour={contact.colour}
            image={contact.image}
            contact
          />
          <p style={{ marginRight: 'auto' }}>{[contact.firstName, contact.lastName].filter(Boolean).join(' ')}</p>
          {new Date(contact.lastContacted).toLocaleDateString(window.navigator.language, { month: 'short', day: 'numeric' })}
        </div>
      ))}
    </>
  );
}

export default ContactWidget;
