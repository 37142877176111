import { useEffect, useState, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import { useClickOutside } from '../../hooks';
import { useContactData, useContactDataUpdate } from '../../../api/ContactDataContext';
import KeepInTouchCard from './KeepInTouchCard';

import './KeepInTouch.scss';
import { useGroupData } from '../../../api/GroupDataContext';
import { ProfilePicture } from '../../common/ProfilePicture';

const getColumns = (contactData) => ({
  [uuid()]: {
    name: "Don't keep in touch",
    value: 0,
    items: contactData.filter((contact) => parseInt(contact.frequencyGoal, 10) === 0),
  },
  [uuid()]: {
    name: 'Every 3 days',
    value: 3,
    items: contactData.filter((contact) => parseInt(contact.frequencyGoal, 10) === 3),
  },
  [uuid()]: {
    name: 'Every week',
    value: 7,
    items: contactData.filter((contact) => parseInt(contact.frequencyGoal, 10) === 7),
  },
  [uuid()]: {
    name: 'Every fortnight',
    value: 14,
    items: contactData.filter((contact) => parseInt(contact.frequencyGoal, 10) === 14),
  },
  [uuid()]: {
    name: 'Every month',
    value: 30,
    items: contactData.filter((contact) => parseInt(contact.frequencyGoal, 10) === 30),
  },
  [uuid()]: {
    name: 'Every 2 months',
    value: 60,
    items: contactData.filter((contact) => parseInt(contact.frequencyGoal, 10) === 60),
  },
  [uuid()]: {
    name: 'Every 4 months',
    value: 120,
    items: contactData.filter((contact) => parseInt(contact.frequencyGoal, 10) === 120),
  },
});

const onDragEnd = (result, columns, setColumns, updateContactById) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);

    destItems.splice(destination.index, 0, removed);
    updateContactById(removed.id, {
      ...removed,
      frequencyGoal: columns[destination.droppableId].value,
    });
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  }
};

export default function KeepInTouch() {
  const { contactData } = useContactData();
  const { updateContactById } = useContactDataUpdate();
  const [columns, setColumns] = useState([]);
  const { groupData } = useGroupData();
  const [selectedGroup, setSelectedGroup] = useState('All Contacts (' + contactData.length + ')');
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [selectGroupOpen, setSelectGroupOpen] = useState(false);
  const dropdownRef = useRef(null);

  useClickOutside(dropdownRef, () => setSelectGroupOpen(false));

  useEffect(() => {
    setColumns(getColumns(contactData.filter((c) => (selectedGroupId === null
      ? true : c.groups.includes(selectedGroupId)))));
  }, [selectedGroupId, contactData]);

  const groupCount = (groupId) => '(' + contactData.filter((c) => (groupId === null ? true : c.groups.includes(groupId))).length + ')';

  const selectGroup = (group) => {
    setSelectedGroup(
      <>
        <ProfilePicture
          name={group.name}
          colour={group.colour}
          image={group.image}
          width="28px"
          height="28px"
        />
        <span>{group.name + ' ' + groupCount(group.id)}</span>
      </>,
    );
    setSelectedGroupId(group.id);
    setSelectGroupOpen(false);
  };
  return (
    <div className="kit-container">
      <h1>Keep In Touch</h1>
      <div className="kit-group-select">
        <div
          className="kit-group-selected"
          ref={dropdownRef}
          role="button"
          tabIndex="0"
          onClick={() => !selectGroupOpen && setSelectGroupOpen(true)}
          onKeyUp={(e) => e.key === 'Enter' && setSelectGroupOpen(false)}
        >
          {selectedGroup}
          {selectGroupOpen
          && (
            <div className="kit-group-list">
              <div
                className="kit-group-item"
                role="button"
                tabIndex="0"
                onClick={() => { setSelectedGroup('All Contacts (' + contactData.length + ')'); setSelectedGroupId(null); setSelectGroupOpen(false); }}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') { return; }
                  setSelectedGroup('All Contacts (' + contactData.length + ')');
                  setSelectedGroupId(null);
                  setSelectGroupOpen(false);
                }}
              >
                <span>{'Show all contacts (' + contactData.length + ')'}</span>
              </div>
              {groupData.map((group, i) => (
                <div
                  key={i}
                  className="kit-group-item"
                  role="button"
                  tabIndex="0"
                  onClick={() => selectGroup(group)}
                  onKeyUp={(e) => e.key === 'Enter' && selectGroup(group)}
                >
                  <ProfilePicture
                    name={group.name}
                    colour={group.colour}
                    image={group.image}
                    width="28px"
                    height="28px"
                  />
                  <span>{group.name + ' ' + groupCount(group.id)}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="kit-dnd">
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, setColumns, updateContactById)}
        >
          {Object.entries(columns).map(([columnId, column]) => (
            <div
              className="kit-goal-list"
              key={columnId}
            >
              <h3>{column.name}</h3>
              <div>
                <Droppable droppableId={columnId} key={columnId}>
                  {(provided, snapshot) => (
                    <div
                      className="kit-goal-droppable"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        border: snapshot.isDraggingOver ? '2px solid var(--neutral-gray)' : 'none',
                        padding: snapshot.isDraggingOver ? 2 : 4,
                        boxShadow: snapshot.isDraggingOver ? '3px 3px 3px rgba(0,0,0,0.1)' : 'none',
                        boxSizing: 'border-box',
                        width: 200,
                        minHeight: 'calc(100vh - 200px)',
                      }}
                    >
                      {column.items.filter((c) => (selectedGroupId === null
                        ? true : c.groups.includes(selectedGroupId))).map((contact, index) => (
                          <Draggable
                            key={contact.id}
                            draggableId={contact.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  userSelect: 'none',
                                  ...provided.draggableProps.style,
                                }}
                              >
                                <KeepInTouchCard
                                  isDragging={snapshot.isDragging}
                                  contact={contact}
                                  goal={column.value}
                                />
                              </div>
                            )}
                          </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
          ))}
        </DragDropContext>
      </div>
    </div>
  );
}
