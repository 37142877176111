/* eslint-disable */
import { useState, useRef, useEffect } from "react";
import { useViewport } from '../../../api/ViewportContext';
import { v4 as uuidv4 } from 'uuid';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap
} from "react-grid-dnd";
import Widget from './Widget';

const MIN_WIDGET_WIDTH = 400;
 
export default function Dashboard() {
  const [items, setItems] = useState(
    [
      {
        id: uuidv4(),
        obj: <Widget type="contacts" />
      },
      {
        id: uuidv4(),
        obj: <Widget type="birthday" />
      },
      {
        id: uuidv4(),
        obj: <Widget type="pie" />
      },
    ]
  ); // supply your own state
  const { width } = useViewport();
  const widgetsRef = useRef(null);
  const [widgetsPerRow, setWidgetsPerRow] = useState();

  useEffect(() => {
    if (widgetsRef.current) {
      const containerWidth = widgetsRef.current.getBoundingClientRect().width;
      setWidgetsPerRow(parseInt(containerWidth / MIN_WIDGET_WIDTH, 10));
    } else {
      setWidgetsPerRow(1);
    }
  }, [widgetsRef, width]);

 
  // target id will only be set if dragging from one dropzone to another.
  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    const nextState = swap(items, sourceIndex, targetIndex);
    setItems(nextState);
  }
 
  return (
    <div className="dashboard-container">
      <h2 className="dashboard-message">
        Welcome!
      </h2>
      <div className="dashboard-widgets" ref={widgetsRef}>
        <GridContextProvider onChange={onChange}>
          <GridDropZone
            id="widgets"
            boxesPerRow={widgetsPerRow}
            rowHeight={300}
            style={{ height: "calc(100vh - 120px)" }}
          >
            {items.map((widget) => (
              <GridItem key={widget.id}>
                <div
                  style={{
                    width: "100%",
                    height: "100%"
                  }}
                >
                  {widget.obj}
                </div>
              </GridItem>
            ))}
          </GridDropZone>
        </GridContextProvider>
      </div>
    </div>
  );
}
