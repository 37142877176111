import { useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import axios from 'axios';
import { Redirect } from 'react-router';
import './Login.scss';

export default function Login() {
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [redirectHome, setRedirectHome] = useState(false);
  // const [failed, setFailed] = useState(false);
  // const [disabled, setDisabled] = useState(false);

  if (document.getElementById('preloader')) {
    document.getElementById('preloader').classList.add('hidden');
  }

  if (redirectHome) {
    return (
      <Redirect to="/" />
    );
  }

  const handleSubmit = () => {
    const postData = { email, password };
    axios
      .post(
        'https://us-central1-phonebookplus-development.cloudfunctions.net/app/login',
        postData,
      )
      .then((res) => {
        // Set global auth token for whenever an axios request is sent
        localStorage.setItem('token', res.data.accessToken);
        localStorage.setItem('refreshToken', res.data.refreshToken);
        axios.defaults.headers.common.Authorization = 'Bearer ' + localStorage.getItem('token');
        setRedirectHome(true);
      });
  };

  const loginWithGoogle = () => {
    const firebaseConfig = {
      apiKey: 'AIzaSyCgXqZ7I3QSTkfbctMgpakw1TTV5Y9s_lM',
      authDomain: 'phonebookplus-86f6b.firebaseapp.com',
      projectId: 'phonebookplus-86f6b',
      storageBucket: 'phonebookplus-86f6b.appspot.com',
      messagingSenderId: '169199119372',
      appId: '1:169199119372:web:c828757390e0c476a05229',
      measurementId: 'G-LZL7NE2S61',
    };

    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const googleProvider = new GoogleAuthProvider();
    signInWithPopup(auth, googleProvider)
      .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        localStorage.setItem('token', token);
        // The signed-in user info.
        setRedirectHome(true);
      // ...
      });
  };

  return (
    <div className="login-container">
      <div className="login-main">
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="button"
          value="Login"
          onClick={() => handleSubmit()}
        />
        <input
          className="google-auth"
          type="button"
          value="Login with Google"
          onClick={() => loginWithGoogle()}
          onKeyUp={(e) => e.key === 'Enter' && loginWithGoogle()}
        />
      </div>
    </div>
  );
}
