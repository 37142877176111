/* eslint-disable no-param-reassign */
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';

import axios from 'axios';
import { useEventDataUpdate } from './EventDataContext';
import { BASE_URL, reauthenticate } from '.';

const contactDataContext = createContext({});
const contactDataUpdateContext = createContext({});

/* function createGroups(contacts) {
  const personalGroups = {};
  const professionalGroups = {};

  const sortObject = (groups) => {
    const ordered = Object.keys(groups).sort().reduce(
      (obj, key) => {
        obj[key] = groups[key];
        return obj;
      },
      {},
    );
    return ordered;
  };

  contacts.forEach((contact) => {
    contact.groups.personal.forEach((personalGroup) => {
      const group = personalGroups[personalGroup];
      if (group) {
        personalGroups[personalGroup].push(contact);
      } else {
        personalGroups[personalGroup] = [contact];
      }
    });

    contact.groups.professional.forEach((professionalGroup) => {
      const group = professionalGroups[professionalGroup];
      if (group) {
        professionalGroups[professionalGroup].push(contact);
      } else {
        professionalGroups[professionalGroup] = [contact];
      }
    });
  });

  return ({
    personal: sortObject(personalGroups),
    professional: sortObject(professionalGroups),
  });
} */

export default function ContactDataProvider({ children }) {
  const [contactData, setContactData] = useState(null);
  const { removeContactFromEvents } = useEventDataUpdate();

  useEffect(() => {
    getContacts();
  }, []);

  function getContacts() {
    axios
      .get(`${BASE_URL}/contacts`)
      .then((res) => {
        setContactData(res.data);
      })
      .catch(() => {
        reauthenticate().then(() => getContacts());
      });
  }

  function uploadImage(id, formImage) {
    if (!formImage) {
      return Promise.resolve();
    }

    formImage.append('contactID', id);
    return (
      axios
        .post(
          `${BASE_URL}/contacts/avatar`,
          formImage,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        )
        .then((res) => res)
    );
  }

  function addContact(contactObject, formImage) {
    return (
      axios
        .post(`${BASE_URL}/contacts`, { ...contactObject })
        .then((res) => {
          contactObject.id = res.data;
          if (formImage) { uploadImage(res.data, formImage); }
          const updatedContacts = contactData.concat(contactObject);
          setContactData(updatedContacts);
        })
        .catch(() => {
          reauthenticate().then(() => addContact(contactObject));
        })
    );
  }

  function updateContactById(id, contactObject, formImage) {
    const updatedContacts = contactData.map((c) => (c.id === id ? contactObject : c));
    setContactData(updatedContacts);
    if (formImage) { uploadImage(id, formImage); }
    axios
      .put(`${BASE_URL}/contacts`, { contactID: id, fields: { ...contactObject } })
      .catch(() => {
        reauthenticate().then(() => updateContactById(id, contactObject));
      });
  }

  // removes the group from all contacts' "groups" field
  // If deleteContacts == true, delete contacts that are ONLY in this group
  //  - if they are in other groups, they can live
  function removeGroupFromContacts(groupId, deleteContacts) {
    let updatedContacts = (deleteContacts ? deleteContactsInGroup(groupId) : contactData);
    updatedContacts = updatedContacts.map((contact) => {
      const updatedContact = contact;
      updatedContact.groups = contact.groups.filter((g) => g !== groupId);
      return updatedContact;
    });
    setContactData(updatedContacts);
  }

  // Handles deleting contacts that are only in this group
  function deleteContactsInGroup(groupId) {
    return contactData.filter((contact) => {
      if ((contact.groups.length === 1 && contact.groups[0] === groupId)) {
        deleteContact(contact.id);
        return false;
      }
      return true;
    });
  }

  function deleteContact(id) {
    const updatedContacts = contactData.filter((c) => c.id !== id);
    removeContactFromEvents(id);
    setContactData(updatedContacts);
    axios
      .post(`${BASE_URL}/contacts/delete`, { contactID: id })
      .catch(() => reauthenticate().then(() => deleteContact(id)));
  }

  return (
    <contactDataUpdateContext.Provider value={{
      addContact, updateContactById, deleteContact, removeGroupFromContacts, deleteContactsInGroup,
    }}
    >
      <contactDataContext.Provider value={{ contactData }}>
        {children}
      </contactDataContext.Provider>
    </contactDataUpdateContext.Provider>
  );
}

export function useContactData() {
  const { contactData } = useContext(contactDataContext);
  return { contactData };
}

export function useContactDataUpdate() {
  const {
    addContact, updateContactById, deleteContact, removeGroupFromContacts, deleteContactsInGroup,
  } = useContext(contactDataUpdateContext);
  return {
    addContact, updateContactById, deleteContact, removeGroupFromContacts, deleteContactsInGroup,
  };
}
