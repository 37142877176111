import { useState } from 'react';
import './BoxToggle.scss';

export default function BoxToggle({ boxContent }) {
  let openView = (localStorage.getItem('contact-box-view') || 0);
  const [content, setContent] = useState(Object.entries(boxContent)[openView
    ? Object.entries(boxContent).findIndex((e) => e[0] === openView) : 0]);

  const setOpenView = (view) => {
    localStorage.setItem('contact-box-view', view);
    openView = view;
  };

  return (
    <div className="cp-box-container">
      <div className="cp-box-tabs">
        {Object.entries(boxContent).map((view, i) => (
          <div
            className={'cp-box-tab' + (view[0] === content[0] ? ' cp-box-active' : '')}
            key={i}
            role="button"
            tabIndex="0"
            onClick={() => { setContent(view); setOpenView(view[0]); }}
            onKeyUp={(e) => { if (e.key === 'Enter') { setContent(view); setOpenView(view[0]); } }}
          >
            <span>{view[0]}</span>
          </div>
        ))}
      </div>
      <div className="cp-box-content">
        {content[1]}
      </div>
    </div>
  );
}
