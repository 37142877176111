import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import axios from 'axios';
import reportWebVitals from './reportWebVitals';

import App from './App';
import { Login } from './components/pages/Login';
import ContextContainer from './api/ContextContainer';

import './styles/layout.scss';
import './styles/customScroll.css';
import './styles/fonts.css';
import './styles/classes.scss';

{
  const url = new URL(window.location.href);
  const token = url.searchParams.get('accessToken');
  const refreshToken = url.searchParams.get('refreshToken');
  if (refreshToken) { localStorage.setItem('refreshToken', refreshToken); }
  if (token) { localStorage.setItem('token', token); }
}

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.common.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => (Promise.reject(err)),
);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/">
          <ContextContainer>
            <App />
          </ContextContainer>
        </Route>
      </Switch>
    </Router>

  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
