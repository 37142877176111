export default function Name(props) {
  const {
    firstName, lastName, setFirstName, setLastName, editing,
  } = props;
  return (
    <div className="cp-name">
      <h3 className="cp-field-label">Name</h3>
      <div className="cp-fname">
        <input
          type="text"
          readOnly={!editing}
          autoComplete="off"
          name="fname"
          maxLength="20"
          value={firstName}
          placeholder="First name"
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div className="cp-lname">
        <input
          type="text"
          readOnly={!editing}
          autoComplete="off"
          name="lname"
          maxLength="20"
          value={lastName}
          placeholder="Last name"
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
    </div>
  );
}
