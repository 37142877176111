import React, { useState, useEffect } from 'react';

import { SearchBox } from '../../common/SearchBox';
import { ChangeThemeButton } from '../../common/buttons/ChangeThemeButton';
import { ProfilePicture } from '../../common/ProfilePicture';
import AddItemDropdown from '../Header/AddItemDropdown';
import { Settings } from '../Header/dropdowns';

import MenuItems from './MenuItems';

import addIcon from '../../../images/svg/icon-add.svg';
import notificationIcon from '../../../images/svg/icon-notification.svg';
// import profileIcon from '../../../images/svg/icon-profile.svg';
import menuIcon from '../../../images/svg/icon-menu-mobile.svg';
import searchIcon from '../../../images/svg/icon-search.svg';

import './NavBarMobile.scss';

export default function NavBarMobile() {
  const [isNotificationOpen, openNotification] = useState(false);
  const [isAddOpen, openAdd] = useState(false);
  const [isSettingsOpen, openSettings] = useState(false);
  const [isMenuOpen, openMenu] = useState(false);
  const [isSearchOpen, openSearch] = useState(false);

  const toggleQuickOptionsMenu = (type) => {
    if (type === 'notifications') { openNotification(!isNotificationOpen); } else { openNotification(false); }
    if (type === 'add') { openAdd(!isAddOpen); } else { openAdd(false); }
    if (type === 'settings') { openSettings(!isSettingsOpen); } else { openSettings(false); }
    if (type === 'menu') { openMenu(!isMenuOpen); } else { openMenu(false); }
    if (type === 'search') { openSearch(!isSearchOpen); } else { openSearch(false); }
  };

  useEffect(() => {
    const clickableElements = document.getElementsByClassName('qo-click');
    const dropDown = document.getElementsByClassName('navbar-quick-options-view')[0];
    document.addEventListener('mousedown', (event) => {
      // if dropDown hasn't rendered or isn't visible, ignore click
      if (!dropDown || dropDown.style.display === 'none') { return; }

      // check elements that are valid to click if they were clicked
      let clicked = false;
      for (let i = 0; i < clickableElements.length; i += 1) {
        if (clickableElements[i].contains(event.target)) {
          clicked = true;
          break;
        }
      }
      // do nothing if a valid element was clicked
      if (clicked) { return; }

      // hide dropdown and toggle quick options menu if non-valid element clicked
      toggleQuickOptionsMenu();
    }, false);
  }, []);

  return (
    <>
      <div className="navbar-mobile qo-click">
        <div
          className="navbar-quick-options-view"
          style={
            (isMenuOpen || isSearchOpen || isSettingsOpen || isAddOpen || isNotificationOpen) ? { display: 'block' } : { display: 'none' }
          }
        >
          {isMenuOpen && <MenuItems closeUI={() => toggleQuickOptionsMenu('menu')} />}
          {isNotificationOpen && <p>These are notifications!</p>}
          {isAddOpen && <AddItemDropdown closeUI={() => toggleQuickOptionsMenu('add')} />}
          {isSettingsOpen
          && (
            <div>
              <Settings fname="Runtime" lname="Terror" />
              <ChangeThemeButton />
            </div>
          )}
          {
          isSearchOpen
          && (
            <div className="navbar-mobile-search">
              <SearchBox width="80%" height="40px" fontSize="16px" />
            </div>
          )
          }
        </div>
        <div className="navbar-quick-options-container">
          <div className="navbar-quick-options-icons">
            <div
              className="navbar-quick-options-profile nav-quick-options-icon"
              onClick={() => toggleQuickOptionsMenu('settings')}
              onKeyUp={(e) => e.key === 'Enter' && toggleQuickOptionsMenu('settings')}
              role="button"
              tabIndex="0"
            >
              <ProfilePicture
                fname="Runtime"
                lname="Terror"
                width="30px"
                height="30px"
                initialCount={2}
                contact
              />
            </div>
            <div
              className="navbar-quick-options-notifications nav-quick-options-icon"
              onClick={() => toggleQuickOptionsMenu('notifications')}
              onKeyUp={(e) => e.key === 'Enter' && toggleQuickOptionsMenu('notifications')}
              role="button"
              tabIndex="0"
            >
              <img
                src={notificationIcon}
                alt=""
                style={{ filter: (isNotificationOpen ? 'drop-shadow(0 2px 2px #aaa)' : 'none') }}
              />
            </div>
            <div
              className="navbar-quick-options-add nav-quick-options-icon"
              onClick={() => toggleQuickOptionsMenu('add')}
              onKeyUp={(e) => e.key === 'Enter' && toggleQuickOptionsMenu('add')}
              role="button"
              tabIndex="0"
            >
              <img
                src={addIcon}
                alt=""
                style={{ filter: (isAddOpen ? 'drop-shadow(0 2px 2px #aaa)' : 'none') }}
              />
            </div>
            <div
              className="navbar-quick-options-search nav-quick-options-icon"
              onClick={() => toggleQuickOptionsMenu('search')}
              onKeyUp={(e) => e.key === 'Enter' && toggleQuickOptionsMenu('search')}
              role="button"
              tabIndex="0"
            >
              <img
                src={searchIcon}
                alt=""
                style={{ filter: (isSearchOpen ? 'drop-shadow(0 2px 2px #aaa)' : 'none') }}
              />
            </div>
            <div
              className="navbar-quick-options-menu nav-quick-options-icon"
              onClick={() => toggleQuickOptionsMenu('menu')}
              onKeyUp={(e) => e.key === 'Enter' && toggleQuickOptionsMenu('menu')}
              role="button"
              tabIndex="0"
            >
              <img
                src={menuIcon}
                alt=""
                style={{ filter: (isMenuOpen ? 'drop-shadow(0 2px 2px #aaa)' : 'none') }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
