import { useState } from 'react';
import Fuse from 'fuse.js';
import { Redirect } from 'react-router';
import { useContactData } from '../../../api/ContactDataContext';
import { useEventData } from '../../../api/EventDataContext';
import { useGroupData } from '../../../api/GroupDataContext';

import SearchResultsContact from './SearchResultContact';
import SearchResultsEvent from './SearchResultEvent';
import SearchResultsGroup from './SearchResultGroup';

import './SearchResults.scss';

const RESULT_TYPES = {
  CONTACT: 'contact',
  GROUP: 'group',
  EVENT: 'event',
};

export default function SearchResults({ query }) {
  const { contactData } = useContactData();
  const { eventData } = useEventData();
  const { groupData } = useGroupData();
  const [redirect, setRedirect] = useState(null);

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  const newContactData = contactData.map((c) => (
    {
      ...c,
      type: RESULT_TYPES.CONTACT,
      name: [c.firstName, c.lastName].filter(Boolean).join(' '),
    }
  ));
  const newEventData = eventData.map((e) => (
    {
      ...e,
      type: RESULT_TYPES.EVENT,
    }
  ));
  const newGroupData = groupData.map((g) => (
    {
      ...g,
      type: RESULT_TYPES.GROUP,
    }
  ));

  const searchData = [...newContactData, ...newEventData, ...newGroupData];

  const fuse = new Fuse(searchData, { keys: ['name'], threshold: 0.1 });

  const results = fuse.search(query);

  const getResultCard = (result) => {
    switch (result.type) {
      case RESULT_TYPES.CONTACT:
        return <SearchResultsContact contact={result} />;
      case RESULT_TYPES.EVENT:
        return <SearchResultsEvent event={result} />;
      case RESULT_TYPES.GROUP:
        return <SearchResultsGroup group={result} />;
      default:
        return <></>;
    }
  };

  return (
    <div className="search-results-container">
      <h2>
        {results.length > 0 ? `Showing results for '${query}'` : 'No results'}
      </h2>
      {results.map((result, i) => (
        <div
          key={i}
          className={`search-result search-result-${result.item.type}`}
          role="button"
          tabIndex="0"
          onClick={() => setRedirect(`/${result.item.type}/${result.item.urlString}/`)}
          onKeyUp={(e) => e.key === 'Enter' && setRedirect(`/${result.item.type}/${result.item.urlString}/`)}
        >
          {getResultCard(result.item)}
        </div>
      ))}
    </div>
  );
}
