import './EditDelete.scss';
import { useState } from 'react';
import iconOptions from '../../../../images/icon-options.png';
import { InteractionBox } from '../../../overlays';
import { SlidingButton } from '../../SlidingButton';

export default function EditDelete({ editFunction, deleteFunction, deleteOption }) {
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteOptionSelected, setDeleteOptionSelected] = useState(false);

  return (
    <>
      <div
        className="options-button"
        role="button"
        tabIndex="0"
        onClick={() => setOptionsOpen(!optionsOpen)}
        onKeyUp={(e) => e.key === 'Enter' && setOptionsOpen(!optionsOpen)}
      >
        <img src={iconOptions} alt="" />
      </div>
      {deleteDialogOpen
      && (
        <InteractionBox
          width="500px"
          height="300px"
          closeUI={() => setDeleteDialogOpen(false)}
        >
          <div className="options-delete-dialog">
            <h3>Are you sure you want to delete?</h3>
            <h3>This action cannot be undone</h3>
            <div className="options-dialog-buttons">
              <div
                className="options-delete-button"
                role="button"
                tabIndex="0"
                onClick={() => deleteFunction(deleteOptionSelected)}
                onKeyDown={(e) => e.key === 'Enter' && deleteFunction(deleteOptionSelected)}
              >
                <span>Delete</span>
              </div>
              <div
                className="options-cancel-button"
                role="button"
                tabIndex="0"
                onClick={() => setDeleteDialogOpen(false)}
                onKeyDown={(e) => e.key === 'Enter' && setDeleteDialogOpen(false)}
              >
                <span>Cancel</span>
              </div>
            </div>
            <div className="options-delete-option">
              <SlidingButton
                position={deleteOptionSelected}
                onChange={() => setDeleteOptionSelected(!deleteOptionSelected)}
              />
              <span>{deleteOption}</span>
            </div>
          </div>
        </InteractionBox>
      )}
      {optionsOpen
        && (
          <div className="options-container">
            <div
              className="option options-edit"
              role="button"
              tabIndex="0"
              onClick={() => editFunction() && setOptionsOpen(false)}
              onKeyDown={(e) => e.key === 'Enter' && (editFunction() && setOptionsOpen(false))}
            >
              <span>Edit</span>
            </div>
            <div
              className="option options-delete"
              role="button"
              tabIndex="0"
              onClick={() => {
                setOptionsOpen(false);
                setDeleteDialogOpen(true);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') { return; }
                setDeleteDialogOpen(true);
                setOptionsOpen(false);
              }}
            >
              <span>Delete</span>
            </div>
          </div>
        )}
    </>
  );
}
