import React, {
  memo, useState, useEffect, useRef,
} from 'react';
import { useUserData } from '../../../api/UserDataContext';
import { ProfilePicture } from '../../common/ProfilePicture';
import { Settings, Notifications } from './dropdowns';
import AddItemDropdown from './AddItemDropdown';
import './Header.scss';
import './HeaderDesktop.scss';

import mainLogo from '../../../images/logo-main.png';

import addIcon from '../../../images/svg/icon-add.svg';
import notificationIcon from '../../../images/svg/icon-notification.svg';

function HeaderDesktop() {
  const [isNotificationOpen, openNotification] = useState(false);
  const [isAddOpen, openAdd] = useState(false);
  const [isSettingsOpen, openSettings] = useState(false);
  const [quickOpen, setQuickOpen] = useState('');
  const dropdownRef = useRef();

  const { user } = useUserData();

  const toggleQuickOptionsMenu = (type) => {
    if (type === 'notifications') { openNotification(!isNotificationOpen); } else { openNotification(false); }
    if (type === 'add') { openAdd(!isAddOpen); } else { openAdd(false); }
    if (type === 'settings') { openSettings(!isSettingsOpen); } else { openSettings(false); }
    setQuickOpen('');
  };

  useEffect(() => {
    const clickableElements = document.getElementsByClassName('qo-click');
    const dropDown = document.getElementsByClassName('header-quick-options-view')[0];
    document.addEventListener('click', (event) => {
      // if dropDown hasn't rendered or isn't visible, ignore click
      if (!dropDown || dropDown.style.display === 'none') { return; }

      // check elements that are valid to click if they were clicked
      let clicked = false;
      for (let i = 0; i < clickableElements.length; i += 1) {
        if (clickableElements[i].contains(event.target)) {
          clicked = true;
          break;
        }
      }
      // do nothing if a valid element was clicked
      if (clicked) { return; }

      // hide dropdown and toggle quick options menu if non-valid element clicked
      toggleQuickOptionsMenu();
    }, false);
  }, []);

  window.onkeydown = (event) => {
    if (['INPUT', 'TEXTAREA'].indexOf(event.target.nodeName) === -1) {
      switch (event.key) {
        case 'c':
          setQuickOpen(event.key);
          openAdd(true);
          break;
        case 'e':
          setQuickOpen(event.key);
          openAdd(true);
          break;
        case 'g':
          setQuickOpen(event.key);
          openAdd(true);
          break;
        case 'i':
          setQuickOpen(event.key);
          openAdd(true);
          break;
        default:
          break;
      }
    }
  };

  if (dropdownRef.current) {
    dropdownRef.current.style.boxShadow = '0px 5px 10px rgba(0, 0, 0, 0.1);';
    dropdownRef.current.style.border = '2px solid var(--neutral-gray)';
  }

  return (
    <div className="header header-desktop">
      <img id="logo-img" src={mainLogo} alt="Phonebook plus" />
      <h1 id="app-title">Phonebook+</h1>
      <div className="header-quick-options-container">
        <div className="header-quick-options-icons">
          <div
            className="header-quick-options-notifications header-quick-options-icon qo-click"
            onClick={() => toggleQuickOptionsMenu('notifications')}
            onKeyUp={(e) => e.key === 'Enter' && toggleQuickOptionsMenu('notifications')}
            role="button"
            tabIndex="0"
          >
            <img src={notificationIcon} alt="" />
          </div>
          <div
            className="header-quick-options-add header-quick-options-icon qo-click"
            onClick={() => toggleQuickOptionsMenu('add')}
            onKeyUp={(e) => e.key === 'Enter' && toggleQuickOptionsMenu('add')}
            role="button"
            tabIndex="0"
          >
            <img src={addIcon} alt="" />
          </div>
          <div
            className="header-quick-options-profile header-quick-options-icon qo-click"
            onClick={() => toggleQuickOptionsMenu('settings')}
            onKeyUp={(e) => e.key === 'Enter' && toggleQuickOptionsMenu('settings')}
            role="button"
            tabIndex="0"
          >
            <ProfilePicture
              fname={user.firstname}
              lname={user.lastname}
              width="35px"
              height="35px"
              initialCount={2}
              contact
            />
          </div>
        </div>
      </div>
      <div
        className="header-quick-options-view qo-click"
        style={(isNotificationOpen || isAddOpen || isSettingsOpen) ? { display: 'block' } : { display: 'none' }}
        ref={dropdownRef}
      >
        {isNotificationOpen && <Notifications />}
        {isAddOpen
          && (
          <AddItemDropdown
            closeUI={() => toggleQuickOptionsMenu('add')}
            dropdownRef={dropdownRef}
            quickOpen={quickOpen}
          />
          )}
        {isSettingsOpen && <Settings fname={user.firstname} lname={user.lastname} />}
      </div>
    </div>
  );
}

export default memo(HeaderDesktop);
