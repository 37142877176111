import {
  useSortedContacts, useSortedContactsUpdate, getSortAttributes, getSortDirections,
} from '../SortingContext';
import dropdownIcon from '../../../../images/icon-dropdown.png';

const sortDirections = getSortDirections();
const sortAttributes = getSortAttributes();

const GS_STATES = [
  {
    ATTR: sortAttributes.GOAL_STATUS.val,
    DIR: sortDirections.ASCENDING,
  },
  {
    ATTR: sortAttributes.GOAL_STATUS.val,
    DIR: sortDirections.DESCENDING,
  },
];

export default function GoalStatusAttr({ props }) {
  const { contact, header } = props;
  const { updateSortData } = useSortedContactsUpdate();

  if (header) {
    const { sortData } = useSortedContacts();
    const index = GS_STATES.findIndex((state) => (
      state.ATTR === sortData.ATTR && state.DIR === sortData.DIR
    ));

    if (index > -1) {
      return (
        <div className="attr-header attr-goal-status">
          <div
            role="button"
            tabIndex="0"
            onClick={() => updateSortData(GS_STATES[(index + 1) % GS_STATES.length])}
            onKeyUp={(e) => e.key === 'Enter' && updateSortData(GS_STATES[(index + 1) % GS_STATES.length])}
          >
            Goal Status
            <img
              src={dropdownIcon}
              alt=""
              style={
                {
                  transform: 'rotate(' + (sortData.DIR === sortDirections.ASCENDING ? '-90' : '90') + 'deg)',
                }
              }
            />
          </div>
        </div>
      );
    }

    return (
      <div
        className="attr-header attr-goal-status"
        role="button"
        tabIndex="0"
        onClick={() => updateSortData(GS_STATES[0])}
        onKeyUp={(e) => e.key === 'Enter' && updateSortData(GS_STATES[0])}
      >
        <span>Goal Status</span>
      </div>
    );
  }

  const lastContactedDays = parseInt((new Date() - new Date(contact.lastContacted))
    / (60 * 60 * 1000 * 24), 10);

  return (
    <div className="attr-container attr-goal-status">
      {contact.frequencyGoal === 0
        ? (
          <></>
        )
        : (
          <span>{lastContactedDays < contact.frequencyGoal ? 'Healthy' : 'Needs Attention'}</span>
        )}
    </div>
  );
}
