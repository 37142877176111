import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ProfilePicture } from '../common/ProfilePicture';
import { useContactData, useContactDataUpdate } from '../../api/ContactDataContext';
import { urlUniqueFriendlyString } from '../common/functions';
import { Tags } from '../common/Tags';

import './AddContact.scss';
import { useGroupData } from '../../api/GroupDataContext';

function AddContact({ closeUI }) {
  const { contactData } = useContactData();
  const { groupData } = useGroupData();
  const { addContact } = useContactDataUpdate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumbers, setPhoneNumbers] = useState(['']);
  const [emails, setEmails] = useState(['']);
  const [failedSave, setFailedSave] = useState(false);
  const [birthdayDay, setBirthdayDay] = useState('');
  const [birthdayMonth, setBirthdayMonth] = useState('');
  const [birthdayYear, setBirthdayYear] = useState('');
  const [birthdayOverlayHidden, setBirthdayOverlayHidden] = useState(false);
  const [groups, setGroups] = useState([]);
  const [colour, setColour] = useState('');
  const [image, setImage] = useState('');
  const [formImage, setFormImage] = useState(null);

  useEffect(() => () => {}, []);

  const isFormValid = (firstName.length + lastName.length > 0);

  const getUrlString = (fname, lname) => {
    const name = [fname, lname].filter(Boolean).join('-');
    const newString = urlUniqueFriendlyString(name,
      contactData.map((contact) => contact.urlString));
    return newString;
  };

  function getYearFromBirthday(day, month, age) {
    const currYear = new Date().getFullYear();
    const birthdayThisYear = new Date(currYear, month, day);
    if (new Date() - birthdayThisYear.setHours(0, 0, 0, 0) < 0) {
      return currYear - age - 1;
    }
    return currYear - age;
  }

  const handleSubmit = () => {
    const formAddContact = document.forms.AddContact;
    const formData = new FormData(formAddContact);

    const fname = formData.get('fname');
    const lname = formData.get('lname');

    const contactObject = {
      urlString: getUrlString(fname, lname),
      id: uuidv4(),
      firstName: fname,
      lastName: lname,
      emails,
      birthday: {
        day: birthdayDay,
        month: birthdayMonth,
        year: birthdayYear < 1000 && birthdayYear !== '' ? getYearFromBirthday(birthdayDay, birthdayMonth, birthdayYear) : birthdayYear,
      },
      phones: phoneNumbers,
      socialMedias: [],
      notes: [],
      communicationHistory: [],
      lastContacted: null,
      groups,
      frequencyGoal: 0,
      image,
      colour,
    };

    // Reset form
    addContact(contactObject, formImage)
      .then(() => {
        setPhoneNumbers(['']);
        setEmails(['']);
        setFirstName('');
        setLastName('');
        if (!formData.get('create-another')) { closeUI(); }
      });
  };

  // BIRTHDAY FIELDS //
  function birthdayEntry() {
    function removeLetters(text) {
      return text.replace(/[^\d]/g, '');
    }
    return (
      <div className="qa-birthday">
        {!birthdayOverlayHidden
        && (
          <input
            type="text"
            placeholder="Birthday"
            readOnly
            onFocus={(e) => {
              e.target.style.display = 'none';
              document.getElementById('birthday-day').focus();
              setBirthdayOverlayHidden(true);
            }}
            style={{ width: '100%' }}
          />
        )}
        <input
          type="text"
          name="birthdayDay"
          id="birthday-day"
          value={birthdayDay}
          placeholder="Day"
          maxLength={2}
          onChange={(e) => setBirthdayDay(removeLetters(e.target.value))}
          style={{ width: '25%', borderTopRightRadius: '0', borderBottomRightRadius: '0' }}
        />
        <input
          type="text"
          name="birthdayMonth"
          value={birthdayMonth}
          placeholder="Month"
          maxLength={2}
          onChange={(e) => setBirthdayMonth(removeLetters(e.target.value))}
          style={{ width: '25%', borderRadius: '0' }}
        />
        <input
          type="text"
          name="birthdayAge"
          value={birthdayYear}
          placeholder="Year or Age (opt)"
          maxLength={4}
          onChange={(e) => setBirthdayYear(removeLetters(e.target.value))}
          style={{ width: '50%', borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}
        />
      </div>
    );
  }

  return (
    <div className="quick-add-container">
      <div className="quick-add-profile-pic">
        <ProfilePicture
          fname={firstName + lastName !== '' ? firstName : 'First name'}
          lname={firstName + lastName !== '' ? lastName : 'Last name'}
          width="120px"
          height="120px"
          initialCount={2}
          contact
          edit
          editFuncs={{ setColour, setImage, setFormImage }}
          colour={colour}
          image={image}
        />
        <h3>Click to modify</h3>
      </div>
      <form action="" id="AddContact">
        <div className="qa-fname">
          <input
            type="text"
            name="fname"
            maxLength="20"
            value={firstName}
            placeholder="First name"
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="qa-lname">
          <input
            type="text"
            name="lname"
            maxLength="20"
            value={lastName}
            placeholder="Last name"
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        {birthdayEntry()}
        <div className="qa-phone-number" style={{ height: phoneNumbers.length * 48 }}>
          {phoneNumbers.map((number, i) => (
            <div key={i}>
              <input
                key={i}
                type="tel"
                name="phone"
                maxLength="20"
                value={number}
                placeholder="Phone number"
                onChange={(e) => {
                  phoneNumbers.splice(i, 1, e.target.value);
                  setPhoneNumbers([...phoneNumbers]);
                }}
              />
              {i === phoneNumbers.length - 1 && (
                <div
                  className="qa-add-phone"
                  role="button"
                  tabIndex="0"
                  onClick={(e) => {
                    if (phoneNumbers[i] !== '') {
                      setPhoneNumbers(phoneNumbers.concat([''])); e.stopPropagation();
                    }
                  }}
                  onKeyUp={(e) => phoneNumbers[i] !== '' && e.key === 'Enter' && setPhoneNumbers(phoneNumbers.concat(['']))}
                >
                  +
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="qa-emails" style={{ height: emails.length * 48 }}>
          {emails.map((email, i) => (
            <div key={i}>
              <input
                key={i}
                type="text"
                name="email"
                title="Email"
                value={email}
                placeholder="Email"
                onChange={(e) => {
                  emails.splice(i, 1, e.target.value);
                  setEmails([...emails]);
                }}
              />
              {(i === emails.length - 1) && (
                <div
                  key={uuidv4()}
                  className="qa-add-email"
                  role="button"
                  tabIndex="0"
                  onClick={(e) => { if (emails[i] !== '') { setEmails(emails.concat([''])); e.stopPropagation(); } }}
                  onKeyUp={(e) => (emails[i] !== '') && e.key === 'Enter' && setEmails(emails.concat(['']))}
                >
                  +
                </div>
              )}
            </div>
          ))}
        </div>
        <Tags
          tags={groups}
          values={groupData}
          onChange={(newGroups) => setGroups(newGroups)}
          element={(groupID) => {
            const group = groupData.find((g) => g.id === groupID);
            return (
              <>
                <ProfilePicture
                  name={group.name}
                  colour={group.colour}
                  image={group.image}
                  width="28px"
                  height="28px"
                />
                {group.name}
              </>
            );
          }}
          text="Add Group"
          searchKeys={['name']}
          editing
        />
        {failedSave && (
          <div className="qa-invalid-message">
            {(firstName.length + lastName.length === 0) && <p>Contact must have a name</p>}
          </div>
        )}
        <div className="qa-submit">
          <input
            type="submit"
            value="Save Contact"
            tabIndex="0"
            onClick={(e) => {
              if (isFormValid) {
                handleSubmit();
                e.preventDefault();
              } else {
                e.preventDefault();
                setFailedSave(true);
              }
            }}
            onKeyDown={(e) => (e.key === 'Enter' && isFormValid && handleSubmit()) || setFailedSave(true)}
            style={{
              color: isFormValid ? '#fff' : '#888',
              backgroundColor: isFormValid ? 'var(--theme-color)' : 'var(--neutral-gray)',
            }}
          />
        </div>
        <div className="qa-create-another">
          <input
            type="checkbox"
            name="create-another"
          />
          <p>Create another</p>
        </div>
      </form>
    </div>
  );
}

export default AddContact;
